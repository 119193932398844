.defaultSection {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    font-family: "din-condensed-variable", sans-serif;

    .playerSection {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;

        .potato {
            height: 250px;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            user-select: all;
        }

        .text {
            font-size: 10vw;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            color: white;
            -webkit-text-stroke: 7.5px black;
            text-shadow: 5px 5px 0 #000, 7.5px 7.5px 0 #000;
            paint-order: stroke fill;
            line-height: 0.65;
            z-index: 1;
        }
    }

    .teamInfo {
        position: relative;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin-top: 50px;

        &.teamB {
            .subText {
                color: white;
            }
        }

        .subText {
            color: black;
            text-align: center;
            font-size: 30px;
            font-weight: bold;
        }

        .largeLetter {
            font-size: 400px;
            color: white;
            -webkit-text-stroke: 1vh black;
            text-shadow: 2px 2px 0 #000000, 5px 5px 0 #000, 10px 10px 0 #000000, 15px 15px 0 #000;
            paint-order: stroke fill;
            text-align: center;
            line-height: 0.65;
        }
    }



    .buttons {
        display: flex;
        flex-direction: column;
        margin-top: 0;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2vh;

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: grey;
                margin: 0;
                font: inherit;
                width: 4vh;
                height: 4vh;
                border-radius: 1vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;

                &:hover {
                    background-color: darkgrey !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 2vh;
                height: 2vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: black;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 1vh;
                font-size: 3vh;
                /*font-family: 'Resistenza';*/
                color: black;
                z-index: 1;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }

    .button {
        font-size: 2.5vh;
        text-align: center;
        color: white;
        height: fit-content;
        width: fit-content;
        padding: 0.25vh;
        padding-left: 3vh;
        padding-right: 3vh;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3vh;
        background-color: white;
        border-radius: 1vh;
        /*font-family: 'Resistenza';*/
        user-select: inherit !important;
        cursor: pointer;
        transform: scale(1);
        color: black;

        &:active {
            transform: scale(1.2);
        }
    }
}
