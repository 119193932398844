@import url('https://fonts.cdnfonts.com/css/d-din');

body {
    font-family: 'D-DIN Condensed', sans-serif;
    touch-action: manipulation;

/*    iframe { // cant use this as stripe payments are an iframe lol
        pointer-events: none;
    }*/
}

.test-buttons {
    position: absolute;
    z-index: 100;
    left: 0;
    pointer-events: auto;
}
.intermission-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.selecting-team-container h1 {
    font-size: 4vh;
    font-weight: 700;
    margin-bottom: 5vh;
    color:white;
    text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);

}
.user-multi-choice-container {
    width: 93%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5%;

    .user-multi-choice-answer-container {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5vh;
        background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%);
        border-radius: 1.5vh;
        border: 0.5vh solid #75ffad;

        .user-multi-choice-answer {
            border: 0.2vh rgba(255,255,255, 0.3) solid;
            font-size: 4vh;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            border-radius: 1vh;
        }
    }
}
.client-toaster-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#d57663 0%, #e97e6b 45.19%, #af5c51 100%);
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.3));
    border: 0.5vh solid #ff8d75;
    border-radius: 1000px;
    width: 8vh;
    height: 8vh;
    margin-top: 3vh;

    .client-toaster-timer-border {
        position: relative;
        width: 80%;
        height: 80%;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        h2 {
            margin: 0;
            font-weight: 600;
            font-size: 3vh;
            text-align: center;
        }
    }
}

.buzzer {
    position: absolute;
    background: #d67763;
    border: 0.5vh solid #ff8d75;
    box-shadow: 0 0 0 0.6vh #682417;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    height: 50vw;
    max-height: 20%;
    aspect-ratio: 2/3;
    transition: transform 0.3s;

    .buzzedInPlayer {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 2.5vh;
        line-height: 1;
        padding: 1vh;
        border-radius: 1vh;
        opacity: 0;
        position: absolute;
        bottom: 0.8vh;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        width: max-content;

        &.show {
            opacity: 1;
        }
    }

    .buzzerOuter {
        position: absolute;
        top: -50%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 200%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background: #d67763;
        border: 0.5vh solid #ff8d75;
        box-shadow: 0 0 0 0.6vh #682417;
        transition: background-color 0.3s;

        button {
            position: absolute;
            margin: 7.5%;
            width: 85%;
            height: 85%;
            border-radius: 50%;
            background-color: #FF2190;
            border: 3px solid #000000;
            box-shadow: 1vh 1vh 0px 0px #00000040, 0 0 6vh 3vh #00000077 inset;
            pointer-events: initial;
            cursor: pointer;
            transition: box-shadow 0.2s, background-color 0.3s;

            .lockedMessage {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: min(6vh, 12vw);
                color: white;
                text-align: center;
                opacity: 0;
                transition: opacity 0.3s;
                font-family: "Impact";
                color: black;
            }

            .cooldown {
                position: absolute;
                bottom: 10%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 4vh;
                color: white;
                text-align: center;
                opacity: 0;
                transition: opacity 0.3s;
                font-family: "Impact";
                color: black;

                &.show {
                    opacity: 0.4;
                }
            }

            &:active {
                box-shadow: 0 0 0 0 #00000040, 0 0 6vh 3vh #00000099 inset;
            }

            &:disabled {
                background-color: lightgray;
                pointer-events: none;

                .lockedMessage {
                    opacity: 0.4;
                }
            }
        }
    }
}


.game-view-header-profile-icon {
    position: relative;
    width: 10vh;
    height: 10vh;
    border-radius: 50%;
    background: #d6776333;
    border: 3px #ff8d75 solid;
    margin-bottom: 1vh;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);

    .header-profile-image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }

    .game-view-header-profile-name {
        position: absolute;
        width: 100%;
        height: 30%;
        background: #d67763;
        border: 0.3vh solid #ff8d75;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
        bottom: -15%;
        border-radius: 0.5vh;

        h3 {
            font-size: 2vh;
            font-weight: 700;
            color: white;
            margin: 0;
            text-align: center;
        }
    }
}

.game-view-header-profile-icon2 {
    position: relative;
    width: 30vh;
    height: 30vh;
    border-radius: 50%;
    background: #d6776333;
    border: 3px #ff8d75 solid;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
    margin-bottom: 1vh;

    .header-profile-image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }

    .game-view-header-profile-name {
        position: absolute;
        width: 100%;
        height: 30%;
        background: #d67763;
        bottom: -15%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5vh solid #ff8d75;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);

        h3 {
            font-size: 4vh;
            font-weight: 700;
            opacity: 1;
            color: white;
            margin: 0;
            text-align: center;
        }
    }
}

.submit-error {
    position: absolute;
    width: 80%;
    height: fit-content;
    background-color: white;
    padding: 2.5%;
    bottom: -15%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
    font-weight: 700;
    color: black;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 35%;
    text-align: center;
    animation: popupFadeOut 3s ease-in-out forwards;
    display: flex;
    z-index: 1;

    .close-button {
        position: absolute;
        margin: auto;
        right: 2.5%;
        top: 2.5%;
        font-weight: 800;
        font-size: 3vh;
    }
}

@keyframes popupFadeOut {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    10% {
        opacity: 1;
        transform: scale(1);
    }

    90% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0);
    }
}

.game-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    font-style: normal;
    font-family: "D-DIN Condensed",sans-serif;



    .game-view-header-profile {
        padding: 10%;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .game-view-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 180px;
        gap: 2vh;
        box-sizing: border-box;
        font-weight: 800 !important;
        font-style: normal;
        font-family: "D-DIN Condensed",sans-serif !important;



        .round-description {
            font-weight: 600;
            width: 50vw;
            padding-top: 1vh;
            font-size: 1.5em;
            text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
            text-align: center;
        }

        .round-sub-text {
            padding-top: 1vh;
            font-size: 1em;
            text-align: center;
        }
    }

    .game-view-content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        width: 100%;
        gap: 1vh;
        padding: 0.5vh; 
        box-sizing: border-box
    }



    @keyframes bounceIn {
        0% {
            transform: scale(1);
            opacity: 0;
        }

        50% {
            transform: scale(1.1);
            opacity: 1;
        }

        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes bounceInConnectFourSpace {
        0% {
            transform: scale(0.9);
            opacity: 0;
        }

        50% {
            transform: scale(1.1);
            opacity: 0.3;
        }

        100% {
            transform: scale(1);
            opacity: 0.3;
        }
    }

    .word-letters-wrapper {
        padding: 3vh 2vh;
        background-color: #75ffad26;
        border-radius: 10px;
        border: 0.5vh solid #75ffad;
        height: fit-content;


        .word-letters-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            gap: 3vh;
            justify-content: center;

            .word-letters-line {
                display: flex;
                flex-direction: row;
                gap: 1vh;
            }

            .user-input-character {
                width: 2.5vh;
                height: 5vh;
                border-bottom: rgba(255, 255, 255, 0.8) 5px solid;
                display: flex;
                filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.6));
                flex-direction: column;
                justify-content: end;
                align-items: center;
                /*animation: bounceIn 0.5s ease-out forwards;*/
                opacity: 1;

                &.highlight {
                    border-bottom: #75ffad 5px solid;
                }

                h1 {
                    color: rgba(255, 255, 255, 1);
                    font-size: 4vh;
                    text-transform: uppercase;
                }
            }

            .space-placeholder {
                width: 2vh;
                height: 0;
            }

            @for $i from 1 through 20 {
                .user-input-character:nth-child(#{$i}) {
                    animation-delay: 0.1s * $i; // Incremental delay for each element
                }
            }
        }
    }



    .word-box-container {
        background-color: white;
        border: #021b35 10px solid;
        width: 80%;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        h1 {
            margin: 0;
            font-size: 1.4em;
            width: fit-content;
            letter-spacing: 10px;
            text-transform: uppercase;
            height: fit-content;
            color: black;
        }
    }


    .confirm-button-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%);
        border: 0.5vh solid #75ffad;
        border-radius: 10px;
        width: 60%;

        .confirm-button {
            border: transparent;
            border-radius: 8px;
            box-sizing: border-box;
            transition: all 0.3s ease-out;
            animation: none;
            color: white;
            font-weight: 600;
            width: 100%;
            height: 6vh;
            text-align: center;
            font-size: 2.5vh;
            align-items: center;
            justify-content: center;
            padding-top: 7px;

            &:hover {
                transform: scale(1.05)
            }
        }
    }



    .keyboard-absolute-container {
        position: relative;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .keyboard-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #75ffad40;
            border: 0.1vh solid #75ffad;
            border-radius: 8px;
            box-sizing: border-box;
            width: 100%;
            padding-bottom: 5vh;

            .keyboard-row {
                display: flex;
                margin: 4px 0;
                width: 100%;
                justify-content: center;
                align-items: center;

                .keyboard-key {
                    width: 9.5%;
                    height: 60px;
                    margin: 0 2.5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    font-weight: bold;
                    color: #2a2a2a;
                    font-weight: 500;
                    background-color: white;
                    border-radius: 5px;
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                    transition: background-color 0.2s ease-in-out, transform 0.1s ease;
                    text-transform: uppercase;
                    border: none;
                    border-bottom: #4a4a4a solid 3px;

                    &.big-key {
                        width: 15%;
                        height: 80px;
                        font-size: 30px;
                    }


                    &:hover {
                        background-color: rgba(255, 255, 255, 0.8);
                        transform: scale(1.05);
                    }

                    &:active {
                        background-color: rgba(255, 255, 255, 1);
                        transform: scale(0.95);
                    }

                    &.space-bar {
                        width: 200px;
                        height: 50px;
                        color: #5b5b5b;
                    }

                    .space-key {
                        width: 80%;
                        height: 1%;
                        border-bottom: 2px #5b5b5b solid;
                    }

                    .delete-key {
                        width: 15px;
                        height: 15px;
                        transform: rotateY(0deg) rotate(45deg);
                        border-left: 2px #5b5b5b solid;
                        border-bottom: 2px #5b5b5b solid;
                    }
                }
            }
        }
    }


    .grid-select-container {
        position: relative;
        width: 95%;
        height: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(57, 107, 77, 0.50);
        border: 3px solid #75ffad;
        border-radius: 0.5vh;
    }

    .map-canvas {
        width: 100%;
        height: 100%;
        display: block;
        cursor: crosshair;
    }
}

.client-selecting-player-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;


    .host-selecting-team-player-container {
        position: relative;
        display: flex;
        width: 85%;
        height: 100%;
        flex-wrap: wrap;
        gap: 5%;
        justify-content: center;
        align-items: center;
        margin-bottom: 2.5%;


        .POINTS-header {
            color: white;
            font-size: 1.5vw;
            text-align: center;
            margin: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 8%;
            font-weight: 800;
            text-shadow: 0px 0px 15px rgba(182, 238, 246, 0.8);
        }

        .player-icon-container {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 2.5%;

            .player-icon {
                position: relative;
                width: 100%;
                aspect-ratio: 1/1;
                border-radius: 40vh;
                background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 0px 1.5vh rgba(182, 238, 246, 0.8);

                &.highlight {
                    background-color: #125dc9;
                }

                .player-icon-image {
                    width: 90%;
                    height: 90%;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    object-fit: cover;
                    border-radius: 100vh;
                }

                .player-icon-name-container {
                    position: absolute;
                    bottom: -15%;
                    height: 25%;
                    width: 105%;
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5vh;
                    box-shadow: 0px 1px 15px rgba(182, 238, 246, 0.5);

                    &.highlight {
                        background-color: #125dc9;

                        h3 {
                            color: white !important;
                        }
                    }

                    h3 {
                        color: #021a2b;
                        font-size: 1.5vh;
                        text-align: center;
                        text-transform: uppercase;
                        margin: 0;
                        width: 90%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}



@keyframes correctJumpIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    50% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
    }

@keyframes icon-shrink-out {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.5);
        opacity: 0;
    }

}

.intermission-profile-animation {
    animation: icon-shrink-out ease-out forwards 0.5s;
}

.absolute-double-text-display {
    position: absolute;
    z-index: 100;
    animation: correctJumpIn ease-in-out forwards 0.5s;
}

.correct-bold-styles {
    color: #51ff24 !important
}.incorrect-bold-styles {
    color: #ff436b !important
}

.bold-text-outline {
    position: relative;
    font-size: 8vh;
    line-height: 1;
    color: black;
    -webkit-text-stroke: 1.2vh #021a2b;
    text-align: center;
    transition: transform 0.3s;
    pointer-events: none;
    opacity: 1;
    transform: scale(1);


    &.fade-out {
        animation: shrink-fade-out 0.3s forwards;
    }

    &.fade-in {
        animation: grow-fade-in 0.3s forwards;
    }

    .bold-text {
        position: absolute;
        color: white;
        -webkit-text-stroke: initial;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        color: white;
        text-align: center;
    }
}




///// ANIMATIONS


.confetti-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Cover full viewport height */
    pointer-events: none; /* Ensure clicks pass through */
    overflow: hidden; /* Prevent overflow due to animation */
    z-index: 9999; /* Place above all other content */
}

/* General particle styles */
.particle {
    position: absolute;
    opacity: 0;
    animation: confetti 5.5s ease-in infinite; /* Adjusted duration for smoother effect */
}

/* Particle colors */
.particle.c1 {
    background-color: rgb(242, 255, 41)
}

.particle.c2 {
    background-color: rgb(255, 92, 183)
}

.particle.c3 {
    background-color: rgb(92, 185, 255)
}

/* Confetti animation */
@keyframes confetti {
    0% {
        opacity: 0;
        transform: translateY(0%) rotate(0deg);
    }

    10% {
        opacity: 1;
    }

    35% {
        transform: translateY(200vh) rotate(270deg); /* Flow down past the viewport */
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(300vh) rotate(1440deg); /* End far below the viewport */
    }
}

.circle-loading {
    width: 50%;
    height: 50%;
    border: 3px solid rgba(255,255,255,0.4);
    border-radius: 50%;
    position: absolute;
    top: 25%;
    left: 25%;
    -webkit-animation: spin 1s infinite linear;
    border-top-color: #fff;
}.circle-loading2 {
    width: 50%;
    height: 50%;
    border: 3px solid rgba(0,0,0,0.4);
    border-radius: 50%;
    position: absolute;
    top: 25%;
    left: 25%;
    -webkit-animation: spin 1s infinite linear;
    border-top-color: #000;
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

