@import url('https://fonts.cdnfonts.com/css/d-din');


.fadeOut {
    animation: fadeOut 0.5s ease forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes popOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.5);
    }
}

.brain-container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;

    h1 {
        transform: translate(-50%, -50%);
        opacity: 0.7;
        color: white;
        font-weight: 700;
        font-size: 2vw;
    }

    .brain-image-container {
        animation: bob 4.5s ease infinite; 
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            animation: talking 1.5s ease-in-out infinite; 
            display: block;
        }
    }
}

@keyframes talking {
    0% {
        transform: scaleX(1) scaleY(1); /* Normal state */
    }

    10% {
        transform: scaleX(1.2) scaleY(0.8); /* Mouth opens wider horizontally */
    }

    20% {
        transform: scaleX(0.9) scaleY(1.1); /* Mouth opens taller vertically */
    }

    30% {
        transform: scaleX(1.15) scaleY(0.85); /* Mouth shifts back to a horizontal stretch */
    }

    40% {
        transform: scaleX(1) scaleY(1); /* Return to normal */
    }

    55% {
        transform: scaleX(1) scaleY(1); /* Return to normal */
    }

    65% {
        transform: scaleX(1.2) scaleY(0.8); /* Mouth opens wider horizontally */
    }

    75% {
        transform: scaleX(0.9) scaleY(1.1); /* Mouth opens taller vertically */
    }

    85% {
        transform: scaleX(1.15) scaleY(0.85); /* Mouth shifts back to a horizontal stretch */
    }

    100% {
        transform: scaleX(1) scaleY(1); /* Return to normal */
    }

}

@keyframes bob {
    0% {
        transform: translate(-50%, -50%);
    }

    50% {
        transform: translate(-50%, -65%);
    }

    100% {
        transform: translate(-50%, -50%);
    }
}

.popOut {
    animation: popOut 0.5s cubic-bezier(.48,.2,.61,1.32) forwards;
}
.uc-door-wrapper {
    position: absolute;
    left: 50.6%;
    top: 39.7vh;
    transform: translate(-50%, -50%);
    width: 49.2vw;
    height: 22vw;
    overflow: visible;
    z-index: 0;

    .uc-door-mask {
        position: relative;
        width: 100%;
        aspect-ratio: 1 / 1;
        overflow: hidden;
    }

    .uc-door {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        transition: transform 1s ease;
        animation: slide-up-and-down .8s cubic-bezier(0.74,1.52,.18,.78) forwards;
        animation-delay: 0.7s;
        transform: translateY(-100%);
        z-index: -1
    }

    @keyframes slide-up-and-down {
        0% {
            transform: translateY(-100%);
        }

        100% {
            transform: translateY(0);
        }
    }
}

.noise-wrapper {
    position: absolute;
    left: 48%;
    top: 51vh;
    border-radius: 1vh;
    transform: translate(-50%, -50%);
    width: 45vw;
    height: 26vw;
    overflow: hidden;
    z-index: -1;
    outline: #05152e 1vh solid;
    transition: all 1s ease-in-out;

    &.zoom {
        transform: scale(0.625) translate(-73%, -105%);
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
    }



    .noise {
        position: absolute;
        top: -500px;
        right: -500px;
        bottom: -500px;
        left: -500px;
        background: transparent url('../../images/noise.png') 0 0;
        background-size: 700px 700px;
        opacity: .45;
        animation: noise .5s steps(8,end) infinite both;
    }

    .unplaced-teams {
        top: 0;
        left: 0;
        border-radius: 1vh;
        width: 100%;
        height: 100%;
        position: absolute;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4vh;
        padding: 3vh;

        .unplaced-players-grid {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            gap: 2vw;
            z-index: -2;
            justify-content: center;
        }

        .unplaced-player {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1 1 100%;
            max-width: 10vw;
            opacity: 0.8
        }

        @keyframes blinkIn {
            0% {
                opacity: 0
            }

            30% {
                opacity: 0.3
            }

            35% {
                opacity: 0
            }

            70% {
                opacity: 0.6
            }


            100% {
                opacity: 0.2 !important
            }
        }

        .animated-player {
            opacity: 0;
            animation: blinkIn 0.3s ease forwards;
            animation-delay: 1.5s;
        }

        .animated-player:nth-of-type(1) {
            animation-delay: 1.1s;
        }

        .animated-player:nth-of-type(2) {
            animation-delay: 1.3s;
        }

        .animated-player:nth-of-type(3) {
            animation-delay: 1.2s;
        }

        .animated-player:nth-of-type(4) {
            animation-delay: 1.5s;
        }

        .animated-player:nth-of-type(5) {
            animation-delay: 1.4s;
        }

        .animated-player:nth-of-type(6) {
            animation-delay: 1.8s;
        }

        .animated-player:nth-of-type(7) {
            animation-delay: 1.7s;
        }

        .animated-player:nth-of-type(8) {
            animation-delay: 1.4s;
        }

        .player-icon2 {
            position: relative;
            width: 100%;
            aspect-ratio: 1 / 1;
            padding: 0.2em;
            background-color: white;
            border-radius: 50%;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;

            .player-icon-image2 {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
                aspect-ratio: 1 / 1;
            }

            .player-icon-name-container2 {
                position: absolute;
                bottom: -1vh;
                height: 18%;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 2vh;
                padding-top: 6px;
                box-shadow: 0px 1px 15px rgba(182, 238, 246, 0.5);

                h3 {
                    color: black;
                    font-size: 2.5vh;
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }
    }


    @keyframes blinkIn {
        0% {
            opacity: 0
        }

        30% {
            opacity: 0
        }

        35% {
            opacity: 0
        }

        50% {
            opacity: 0.3
        }

        55% {
            opacity: 0
        }

        70% {
            opacity: 0.7
        }

        75% {
            opacity: 0
        }

        100% {
            opacity: 1
        }
    }






    @keyframes glitch-with-shadow {
        0% {
            transform: translate(-50%, -50%);
            filter: none;
        }

        5% {
            transform: translate(-52%, -50%);
            filter: drop-shadow(3px 0px #5effd337);
        }

        10% {
            transform: translate(-50%, -48%);
            filter: drop-shadow(0px -3px #5EFFD337);
        }

        15% {
            filter: none;
        }

        25% {
            transform: translate(-50%, -50%);
            filter: drop-shadow(-3px 3px #ff5e5e37);
        }

        30% {
            transform: translate(-49%, -49%);
            filter: drop-shadow(-3px 0px #5EFFD337);
        }

        35% {
            transform: translate(-51%, -49%);
            filter: drop-shadow(3px 3px #5EFFD337);
        }

        40% {
            filter: none;
        }

        60% {
            transform: translate(-48%, -52%);
            filter: drop-shadow(-3px -3px #ff5e5e37);
        }

        70% {
            transform: translate(-49%, -49%);
            filter: drop-shadow(-3px 3px #5EFFD337);
        }

        80% {
            transform: translate(-50%, -52%);
            filter: drop-shadow(3px -3px #ff5e5e37);
        }

        90% {
            transform: translate(-50%, -50%);
            filter: drop-shadow(-3px 0px #5EFFD337);
        }

        100% {
            transform: translate(-51%, -51%);
            filter: drop-shadow(-3px 3px #5EFFD337);
        }
    }
}




@keyframes noise {
    0% {
        transform: translateX(0px,0px);
    }

    10% {
        transform: translate(-100px, 100px);
    }

    20% {
        transform: translate(150px, -100px);
    }

    30% {
        transform: translate(-100px,100px);
    }

    40% {
        transform: translate(100px, -150px);
    }

    50% {
        transform: translate(-100px, 200px);
    }

    60% {
        transform: translate(-200px, -100px);
    }

    70% {
        transform: translateY(50px, 100px);
    }

    80% {
        transform: translate(100px, -150px);
    }

    90% {
        transform: translate(0px, 200px);
    }

    100% {
        transform: translate(-100px, 100px);
    }
}


.host-main-game-container {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh !important;
    justify-self: center;
    align-self: center;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 800 !important;
    font-family: 'D-DIN Condensed', sans-serif;

    .bold-header {
        font-weight: 600 !important
    }

    .host-selecting-team-teams-container {
        display: flex;
        flex-direction: row;
        gap: 1vw;

        .host-selecting-team-team-container {
            display: flex;
            flex-direction: column;
            height: 60vh;
        }
    }

    @keyframes HCFCBob {
        0% {
            transform: translateY(120%);
            opacity: 0
        }

        50% {
            transform: translateY(-15px);
            opacity: 0.5
        }

        100% {
            transform: translateY(0);
            opacity: 1
        }
    }

    .host-connect-four-container {
        width: 100%;
        height: 90vh;
        display: flex;
        flex-direction: column;
        gap: 1vh;
        justify-content: center;
        align-items: center;


        .host-connect-four-hints-wrapper {
            position: relative;
            width: 100%;
            height: 46vh;
            display: flex;
            flex-direction: column;
            gap: 1vh;

            .connect-four-points-container {
                width: 10vh;
                height: 10vh;
                background-color: #fff;
                border-radius: 1000px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: -5vh;
                z-index: 100;
                transition: transform 0.5s ease-in-out;


                &.flash {
                    animation: flashRed 2s infinite;
                }

                @keyframes flashRed {
                    0% {
                        background-color: #fff;
                    }

                    50% {
                        background-color: red;
                    }

                    100% {
                        background-color: #fff;
                    }
                }


                h1 {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    color: black;
                    font-weight: 700;
                    opacity: 0.8;
                    font-size: 4vh;
                }


                .connect-four-sec {
                    height: 90%;
                    width: 4%;
                    display: flex;
                    position: absolute;

                    .connect-four-capsule {
                        position: absolute;
                        width: 100%;
                        height: 12%;
                        background-color: #ddd;
                        border-radius: 3px 3px 0 0;
                    }
                }
            }

            @keyframes popIn {
                0% {
                    transform: scale(0.5);
                    opacity: 0;
                }

                100% {
                    transform: scale(1);
                    opacity: 1;
                }
            }

            .host-connect-four-hint-wrapper {
                position: relative;
                width: 35vw;
                padding: 1vh;
                max-height: 10vh;
                height: fit-content;
                display: flex;
                justify-content: center;
                -webkit-align-items: inherit;
                align-items: inherit;
                -moz-border-radius: inherit;
                border: 0.4vh solid #b1ffd0;
                -webkit-border-radius: inherit;
                border-radius: 1.8vh;
                animation: popIn 0.5s ease-in-out forwards;
                overflow: hidden;


                .blur-hint {
                    top: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    backdrop-filter: blur(16px);
                    --webkit-backdrop-filter: blur(16px);
                    background-color: rgba(255, 255, 255, 0.38);
                    overflow: hidden;
                    border-radius: 1.3vh;
                }

                .host-connect-four-hint-container {
                    width: 100%;
                    height: 8vh;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    border-radius: 1.5vh;



                    h2 {
                        color: white;
                        font-size: 4vh;
                        font-weight: 400 !important;
                        text-align: center;
                        justify-self: center;
                        height: fit-content;
                        margin: 0;
                        padding-bottom: 1vh;
                        z-index: 100;
                        text-shadow: 3px 2px 1px rgba(0, 0, 0, 0.4);
                    }
                }
            }
            /*
            .host-connect-four-hint-wrapper:nth-child(2) .host-connect-four-hint-container {
                border: #FF9E9E 0.5vh solid;
            }

            .host-connect-four-hint-wrapper:nth-child(3) .host-connect-four-hint-container {
                border: #FF9E9E 0.5vh solid;
            }

            .host-connect-four-hint-wrapper:nth-child(4) .host-connect-four-hint-container {
                border: #FF9E9E 0.5vh solid;
            }

            .host-connect-four-hint-wrapper:nth-child(5) .host-connect-four-hint-container {
                border: #FF9E9E 0.5vh solid;
            }*/
        }
        /*
        .host-connect-four-hint-wrapper:nth-child(2) {
            background-color: #ff70cbd9 !important;
        }

        .host-connect-four-hint-wrapper:nth-child(3) {
            background-color: #ff7070d9 !important;
        }

        .host-connect-four-hint-wrapper:nth-child(4) {
            background-color: #9effc5d9 !important;
        }

        .host-connect-four-hint-wrapper:nth-child(5) {
            background-color: #70b8ffd9 !important;
        }*/
    }

    @keyframes show-correct-answer-bounce {
        0% {
            transform: translateY(0) scale(1);
            opacity: 1
        }

        10% {
            transform: translateY(0) scale(1.1);
            opacity: 1
        }

        40% {
            transform: translateY(0) scale(1.1) rotate(-10deg);
            opacity: 1
        }

        65% {
            transform: translateY(0) scale(1.1) rotate(10deg);
            opacity: 1
        }


        80% {
            transform: translateY(0) scale(1.1) rotate(0);
            opacity: 1
        }

        100% {
            transform: translateY(0) scale(1);
            opacity: 1
        }
    }

    .show-correct-answer-bounce {
        animation: show-correct-answer-bounce 0.75s ease-in-out forwards;
    }

    .CFLB-start {
        animation: HCFCBob 0.5s ease-in-out forwards;
        animation-delay: 0.9s;
    }

    .multiple-choice-container {
        position: absolute;
        width: 40vw;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        bottom: 1vh;
        gap: 1vh;

        @keyframes border-pulse {
            0% {
                border: 0.2vh solid rgba(255,255,255, 0.1);
            }

            50% {
                border: 0.1vh solid #75ffad;
            }

            100% {
                border: 0.2vh solid rgba(255,255,255, 0.1);
            }
        }



        .multiple-choice-option-container {
            width: 100%;
            height: 100%;
            border-radius: 1.5vh;
            padding: 1vh;
            background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%);
            display: flex;
            flex-direction: column;
            gap: 1vh;
            border: 0.5vh solid #75ffad;

            &.correct {
                animation: show-correct-answer-bounce 0.75s ease-in-out forwards;
            }

            .multiple-choice-option {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 1vh;
                border: white 0vh solid;
                animation: border-pulse 3s ease-in-out infinite;

                &.correct {
                    background-color: #29ab78 !important;
                    border: none !important;
                }

                h1 {
                    color: white;
                    font-weight: 700;
                    font-size: 3vh;
                    line-height: 1;
                    margin: 0;
                }
            }
        }
    }

    .multiple-choice-container {
        .multiple-choice-option-container:nth-child(1) .multiple-choice-option {
            animation-delay: 0.8s;
        }

        .multiple-choice-option-container:nth-child(2) .multiple-choice-option {
            animation-delay: 1.6s;
        }

        .multiple-choice-option-container:nth-child(3) .multiple-choice-option {
            animation-delay: 2.4s;
        }
    }

    .connect-four-letter-box-container {
        position: absolute;
        bottom: 1vh;
        background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%);
        padding: 4vh 6vh;
        border-radius: 1.5vh;
        border: 0.5vh solid #75FFAD;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: fit-content;
        gap: 0.5vw;
        opacity: 0;

        .connect-four-letter {
            width: 3vh;
            height: 5vh;
            background: rgba(112, 236, 255, 0);
            border-bottom: 0.5vh solid white;

            h1 {
                text-transform: uppercase;
                text-align: center;
                color: white;
                font-size: 4vh;
                margin-bottom: 1vh;
            }
        }

        .connect-four-space {
            opacity: 0;
        }
    }

    .host-witw-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 93vh;
        width: 100vh;
        top: 5vh;
        position: relative;

        .WITW-image {
            width: 300px;
        }

        h1 {
            color: white;
            font-size: 3vh;
            text-align: center;
        }

        .map-canvas-holder {
            border: 3px solid #b1ffd0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 52vw;
            height: 70%;
            border-radius: 3vh;
            z-index: -1;
            background: rgba(58, 58, 58, 0.4);
            max-width: 850px;
        }
    }

    .map-canvas {
        width: 100%;
        height: auto;
    }

    .lyric-linguist-container {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //animation: HCFCBob 1.2s ease-in-out forwards;
        //animation-delay: 0.5s;
        opacity: 1;
        transform: scale(0);
        transition: transform 0.5s cubic-bezier(.51,-0.58,1,.87);

        &.show {
            transform: scale(1);
            transition: transform 0.5s cubic-bezier(.17,.67,.61,1.26);
        }

        .lyric-linguist-timer {
            position: absolute;
            top: -4vh;
            right: -4vh;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(#d57663 0%, #e97e6b 45.19%, #af5c51 100%);
            border: 0.2vw solid #ff8d75;
            border-radius: 1000px;
            width: 8vh;
            height: 8vh;
            z-index: 100;

            .lyric-linguist-timer-border {
                position: relative;
                width: 80%;
                height: 80%;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                h2 {
                    margin: 0;
                    font-weight: 600;
                    font-size: 4vh;
                    color: white;
                    text-align: center;
                    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
                }
            }
        }
    }

    .lyric-linguist-video-wrapper {
        position: absolute;
        height: 50vh;
        width: 89vh;
        top: 5vh;
        left: 50%;
        transform: translateX(-50%);
        margin: 0 auto;
        border-radius: 1vh;
        box-shadow: 0 0 0 0.4vh #75ffad;
        outline: rgba(255,255,255,0.15) solid 1.5vh;

        .lyric-linguist-video {
            background-color: #75ffad;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden
        }
    }

    .spelling-bee-container {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: HCFCBob 1.2s ease-in-out forwards;
        animation-delay: 0.5s;
        opacity: 0;
    }

    .host-missing-vowels-container {
        height: 90vh;
        display: flex;
        flex-direction: column;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-align-content: center;
        -webkit-align-content: center;
        align-content: center;

        .host-missing-vowels-letter-box-container {
            display: flex;
            flex-direction: row;
            height: fit-content;
            flex-wrap: wrap;
            gap: 7.5vh;
            width: 80vh;
            justify-content: center;
            align-items: center;

            .missing-vowels-line {
                display: flex;
                flex-direction: row;
                gap: 0.5vh;
                width: 100%;
                justify-content: center;
                align-items: center;
            }

            .missing-vowels-letter {
                position: relative;
                width: 10%;
                height: 10vh;
                border-radius: 1vh;
                background-color: transparent;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                flex-direction: column;

                .underline-placeholder {
                    width: 100%;
                    height: 3vh;
                    border: #011b34 1vh solid;
                    border-radius: 1vh;
                    background-color: white;
                    position: absolute;
                    bottom: -4vh;
                }
            }

            .missing-vowels-space {
                opacity: 0;
            }
        }

        .host-missing-vowels-subject-container {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            bottom: 5vh;
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 1vh;
            animation: HCFCBob 0.8s ease-in-out forwards;
            animation-delay: 0.9s;
            opacity: 0;

            h1 {
                color: white;
                font-size: 2vh;
                text-align: center;
                margin-bottom: 0;
            }

            .host-missing-vowels-subject-box {
                width: 40vw;
                height: fit-content;
                padding: 1.5vh 5vh;
                background-color: white;
                border: #011b34 1.2vh solid;
                border-radius: 1.2vh;


                h2 {
                    color: #001933;
                    font-size: 3vh;
                    text-align: center;
                }
            }
        }
    }





    @keyframes popIn {
        0% {
            transform: scale(0.5);
            opacity: 0;
        }

        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    .pointAdd {
        position: absolute;
        color: white;
        font-size: 4.75vh;
        margin: auto;
        text-align: center;
        top: 0;
        bottom: 0;
        right: 25%;
        left: unset;
        transform: translateY(5vh);
        opacity: 1;
        animation: pointsAnim 2s ease-in-out forwards;
    }

    @keyframes pointsAnim {
        0% {
            transform: translateY(5vh);
            opacity: 1;
        }

        100% {
            transform: translateY(-2vh);
            opacity: 0;
        }
    }


    .host-selecting-team-container {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -45%);
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 95vw;
        z-index: -2;


        .host-selecting-team-teams-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 10vh;








            .host-selecting-team-team-container {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                animation-duration: 0.6s;
                animation-timing-function: ease-out;
                animation-fill-mode: forwards;
                animation-delay: 1s;
                opacity: 0;

                &:nth-of-type(1) {
                    animation-name: slideInLeft;
                }

                &:nth-of-type(2) {
                    animation-name: slideInRight;
                }

                .team-header-style {
                    color: white;
                    margin: 0;
                    font-weight: 800;
                    font-size: 3vh;
                    text-shadow: 0px 0px 15px rgba(182, 238, 246, 0.8);
                }

                .host-team-info-container {
                    position: absolute;
                    top: 0%;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    left: 50%;
                    width: 50%;
                    z-index: 10;
                    transform: translateX(-50%);

                    .host-team-points-container {
                        width: 100%;
                        text-align: center;
                        color: white;
                        display: flex;
                        flex-direction: column;
                        background-color: transparent;
                        border-radius: 10vh;
                        border: 0.5vh solid #fff;
                        box-shadow: 0px 0px 15px rgba(182, 238, 246,0.8);
                        height: 5vh;

                        &::before {
                            content: "";
                            height: 3vh;
                            position: absolute;
                            border-radius: 100px;
                            box-shadow: inset 0px 0px 15px rgba(182, 238, 246, 0.8);
                            pointer-events: none;
                        }

                        h1 {
                            font-weight: 800;
                            font-size: 3vh;
                            text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
                        }
                    }
                }

                .host-selecting-team-player-wrapper {
                    display: flex;
                    flex-direction: column;
                    width: 100vh;
                    max-width: 20vw;
                    min-width: 12vw;
                    height: 80%;
                    border: 0.5vh solid white;
                    border-radius: 4vh;
                    background: rgba(0, 0, 0, 0.31);
                    justify-content: center;
                    align-items: center;
                    padding: 1vh;
                    box-sizing: border-box;
                    clip-path: polygon(0% 0%, 0% 100%, 74% 100%, 25% 0, 74% 0, 73% 4%, 27% 4%, 28% 100%, 100% 100%, 100% 0%);


                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 80%;
                        border-radius: 4vh;
                        box-shadow: inset 0px 0px 1.5vh rgba(182, 238, 246, 0.8);
                        pointer-events: none;
                    }




                    .blur-selecting-teams {
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        position: absolute;
                        z-index: -1;
                        backdrop-filter: blur(3em);
                        opacity: 0.25;
                        border-radius: 2vh;
                        background-color: #8d8d8d;
                    }


                    .player-container-grid1 {
                        grid-template-columns: 100% !important;
                        grid-auto-rows: 100%;
                        justify-content: center;
                        align-content: center;
                    }

                    .player-container-grid2 {
                        grid-template-columns: 100% !important;
                        grid-row: 1/2;
                        justify-content: center;
                        align-content: center;
                        padding: 1vh !important;

                        .player-icon-container {
                            width: 13vh !important;
                            margin-bottom: 5vh;
                        }
                    }

                    .player-container-grid3 {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: auto auto;
                        justify-items: center;
                        align-items: center;

                        .player-icon-container {
                            width: 12vh !important;

                            &:nth-child(2) {
                                grid-column: 1 / 2;
                                grid-row: 1;
                            }

                            &:nth-child(3) {
                                grid-column: 2 / 3;
                                grid-row: 1;
                            }

                            &:nth-child(4) {
                                grid-column: 1 / 3;
                                grid-row: 2;
                            }
                        }
                    }

                    .player-container-grid4 {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: auto auto;
                        justify-items: center;
                        align-items: center;

                        .player-icon-container {
                            width: 12vh !important;

                            &:nth-child(2) {
                                grid-column: 1 / 2;
                                grid-row: 1;
                            }

                            &:nth-child(3) {
                                grid-column: 2 / 3;
                                grid-row: 1;
                            }

                            &:nth-child(4) {
                                grid-column: 1 / 2;
                                grid-row: 2;
                            }

                            &:nth-child(5) {
                                grid-column: 2 / 3;
                                grid-row: 2;
                            }
                        }
                    }

                    .player-container-grid5 {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: auto auto;
                        justify-items: center;
                        align-items: center;
                        padding: 3vh !important;
                        grid-gap: 2vh;
                        grid-row-gap: 2vh;

                        .player-icon-container {
                            width: 10vh !important;

                            &:nth-child(2) {
                                grid-column: 1 / 2;
                                grid-row: 1;
                            }

                            &:nth-child(3) {
                                grid-column: 2 / 3;
                                grid-row: 1;
                            }

                            &:nth-child(4) {
                                grid-column: 1 / 2;
                                grid-row: 2;
                            }

                            &:nth-child(5) {
                                grid-column: 2 / 3;
                                grid-row: 2;
                            }

                            &:nth-child(6) {
                                grid-column: 1 / 3;
                                grid-row: 3;
                            }
                        }
                    }

                    .player-container-grid6 {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: auto auto;
                        justify-items: center;
                        align-items: center;
                        padding: 3vh !important;
                        grid-gap: 2vh;
                        grid-row-gap: 2vh;

                        .player-icon-container {
                            width: 10vh !important;

                            &:nth-child(2) {
                                grid-column: 1 / 2;
                                grid-row: 1;
                            }

                            &:nth-child(3) {
                                grid-column: 2 / 3;
                                grid-row: 1;
                            }

                            &:nth-child(4) {
                                grid-column: 1 / 2;
                                grid-row: 2;
                            }

                            &:nth-child(5) {
                                grid-column: 2 / 3;
                                grid-row: 2;
                            }

                            &:nth-child(6) {
                                grid-column: 1 / 2;
                                grid-row: 3;
                            }

                            &:nth-child(7) {
                                grid-column: 2 / 3;
                                grid-row: 3;
                            }
                        }
                    }

                    .player-container-grid7 {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: auto auto;
                        justify-items: center;
                        align-items: center;
                        padding: 3vh !important;
                        grid-gap: 2vh;
                        grid-row-gap: 2vh;

                        .player-icon-container {
                            width: 8vh !important;

                            &:nth-child(2) {
                                grid-column: 1 / 2;
                                grid-row: 1;
                            }

                            &:nth-child(3) {
                                grid-column: 2 / 3;
                                grid-row: 1;
                            }

                            &:nth-child(4) {
                                grid-column: 1 / 2;
                                grid-row: 2;
                            }

                            &:nth-child(5) {
                                grid-column: 2 / 3;
                                grid-row: 2;
                            }

                            &:nth-child(6) {
                                grid-column: 1 / 2;
                                grid-row: 3;
                            }

                            &:nth-child(7) {
                                grid-column: 2 / 3;
                                grid-row: 3;
                            }

                            &:nth-child(8) {
                                grid-column: 1 / 3;
                                grid-row: 4;
                            }
                        }
                    }

                    .host-selecting-team-player-container {
                        position: relative;
                        display: grid;
                        grid-template-columns: 50% 50%;
                        grid-gap: 1vh 1vh;
                        grid-auto-rows: min-content;
                        width: 100%;
                        height: 100%;
                        border: white 0.3vh solid;
                        border-radius: 2.5vh;
                        padding: 6vh 2vh;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border-radius: 2vh;
                            pointer-events: none;
                        }

                        .POINTS-header {
                            color: white;
                            font-size: 1.5vw;
                            text-align: center;
                            margin: 0;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            top: 8%;
                            font-weight: 800;
                            text-shadow: 0px 0px 15px rgba(182, 238, 246, 0.8);
                        }

                        .player-icon-container {
                            width: 50%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            justify-self: center;


                            .player-icon {
                                position: relative;
                                width: 100%;
                                aspect-ratio: 1/1;
                                border-radius: 40vh;
                                background-color: #fff;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                box-shadow: 0px 0px 1.5vh rgba(182, 238, 246, 0.8);

                                &.highlight {
                                    background-color: #125dc9;
                                }

                                &.highlightCorrect {
                                    background-color: #29ab78;
                                }

                                &.highlightIncorrect {
                                    background-color: #c92b2b;
                                }

                                .player-icon-image {
                                    width: 90%;
                                    height: 90%;
                                    aspect-ratio: 1/1;
                                    border-radius: 50%;
                                    object-fit: cover;
                                    border-radius: 100vh;
                                }

                                .player-icon-name-container {
                                    position: absolute;
                                    bottom: -15%;
                                    height: 25%;
                                    width: 105%;
                                    background-color: white;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 5vh;
                                    box-shadow: 0px 1px 15px rgba(182, 238, 246, 0.5);

                                    &.highlight {
                                        background-color: #125dc9;

                                        h3 {
                                            color: white !important;
                                        }
                                    }

                                    &.highlightCorrect {
                                        background-color: #29ab78;

                                        h3 {
                                            color: white !important;
                                        }
                                    }

                                    &.highlightIncorrect {
                                        background-color: #c92b2b;

                                        h3 {
                                            color: white !important;
                                        }
                                    }



                                    h3 {
                                        color: #021a2b;
                                        font-size: 2vh;
                                        text-align: center;
                                        text-transform: uppercase;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @keyframes slideInLeft {
                from {
                    transform: translateX(-100%);
                    opacity: 0;
                }

                to {
                    transform: translateX(0);
                    opacity: 1;
                }
            }

            @keyframes slideInRight {
                from {
                    transform: translateX(100%);
                    opacity: 0;
                }

                to {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }
    }

    @keyframes slideDownFadeIn {
        0% {
            opacity: 0;
            transform: translateY(-100%);
            transform: translateX(-50%);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
            transform: translateX(-50%);
        }
    }


    @keyframes slideUpFadeIn {
        0% {
            opacity: 0;
            transform: translateY(100%);
            transform: translateX(-50%);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
            transform: translateX(-50%);
        }
    }

    @keyframes wobble {
        0% {
            transform: scale(1);
        }



        50% {
            transform: scale(1.1);
        }



        100% {
            transform: scale(1);
        }
    }



    .wobble {
        animation: wobble 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .header-toaster-tp-wrapper {
        position: absolute;
        top: 7vh;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;

        .header-toaster-wrapper {
            padding: 0.5vh;
            position: relative;
            display: flex;
            max-width: fit-content;
            width: 100%;
            white-space: nowrap;
            height: fit-content;
            padding: 1.2vh;
            box-sizing: border-box;
            background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%);
            border: 0.5vh solid #75ffad;
            border-radius: 1.5vh;
            opacity: 1;
            transform-origin: 50% top;

            .header-toaster-container {
                width: fit-content;
                border-radius: 1vh;
                height: fit-content;
                padding: 0 5vh;
                color: white;
                font-weight: 500;
                font-size: 5vh;
                text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.34);
                display: flex;
                flex-direction: column;
                justify-content: center;

                h1 {
                    margin: 0;
                    font-weight: 800;
                    font-size: 6vh;
                }

                .header-toaster-timer {
                    position: absolute;
                    top: -2vh;
                    right: -2vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: linear-gradient(#d57663 0%, #e97e6b 45.19%, #af5c51 100%);
                    border: 0.2vw solid #ff8d75;
                    border-radius: 1000px;
                    width: 6vh;
                    height: 6vh;
                    z-index: 100;

                    &.flashHeader {
                        animation: flashHeader linear 2s infinite;
                    }

                    @keyframes flashHeader {
                        0% {
                            background-color: #00192e;
                        }

                        50% {
                            background-color: red;
                        }

                        100% {
                            background-color: #00192e;
                        }
                    }


                    .header-toaster-timer-border {
                        position: relative;
                        width: 80%;
                        height: 80%;
                        border-radius: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        h2 {
                            margin: 0;
                            font-weight: 600;
                            font-size: 3vh;
                            text-align: center;
                            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
                        }
                    }
                }
            }
        }
    }

    @keyframes slideDown {
        0% {
            transform: translateY(0) translateX(-50%);
            opacity: 1;
        }

        100% {
            transform: translateY(200px) translateX(-50%);
            opacity: 0;
        }
    }

    @keyframes slideUp {
        0% {
            transform: translateY(200px) translateX(-50%);
            opacity: 0;
        }

        100% {
            transform: translateY(0) translateX(-50%);
            opacity: 1;
        }
    }

    .slide-down {
        animation: slideDown 0.4s cubic-bezier(.45,.96,.48,1) forwards;
    }

    .slide-up {
        animation: slideUp 0.4s cubic-bezier(.45,.96,.48,1) forwards;
    }

    .footer-toaster-wrapper {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        width: 50vw;
        height: fit-content;
        padding: 0.5vh;
        box-sizing: border-box;
        background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%);
        border: 0.5vh solid #75ffad;
        border-radius: 15px;

        .footer-toaster-container {
            width: 100%;
            border-radius: 10px;
            height: fit-content;
            padding: 2vh 5vh;
            color: white;
            font-weight: 500;
            font-size: 3.5vh;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.34);
            text-align: center;
        }
    }

    .host-speaking-container {

        .host-speaking-text-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .host-text-overrid {
            animation: popIn 0.4s cubic-bezier(.45,.96,.48,1) forwards;

            @keyframes popIn {
                0% {
                    transform: scale(0);
                }

                100% {
                    transform: scale(1);
                }
            }
        }
    }

    .picture-frame-container {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: HCFCBob 1.2s ease-in-out forwards;
        animation-delay: 0.5s;
        opacity: 0;

        .picture-frame-image {
            width: 100%;
            max-width: 20vw;
            border: 1vh #021a2b solid;
        }
    }

    .face-off-container {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: HCFCBob 1.2s ease-in-out forwards;
        animation-delay: 0.5s;
        opacity: 0;

        .face-off-group {
            display: flex;
            width: fit-content;
            height: fit-content;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .face-off-image {
            border-radius: 500px;
            width: 47vh;
            aspect-ratio: 1/1;
            object-fit: cover;
            border: 0.5vh solid #75ffad;
            outline: rgba(255, 255, 255, 0.1) solid 1.5vh;
            margin-bottom: 4vh;
        }

        .face-off-name {
            position: absolute;
            bottom: 1vh;
            height: fit-content;
            padding: 2%;
            padding-left: 5%;
            padding-right: 5%;
            left: 0;
            margin: auto;
            right: 0;
            width: fit-content;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5vh;
            padding-top: 6px;
            font-size: 5vh;
            box-shadow: 0px 1px 15px rgba(182, 238, 246, 0.5);
            color: black;
            text-align: center;
            text-transform: uppercase;
        }
    }



    .scene-it-container {
        height: 95vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: HCFCBob 1.2s ease-in-out forwards;
        animation-delay: 0.5s;
        opacity: 0;

        .scene-it-image {
            width: 100%;
            max-width: 50vh;
            border: 3px solid #75ffad;
            outline: rgba(255, 255, 255, 0.15) 2vh solid;
            border-radius: 2vh;
        }
    }

    .extra-bold {
        -webkit-text-stroke: 2vh #00192d !important;
    }

    .bold-text-outline {
        position: relative;
        font-size: 12vh;
        line-height: 1;
        color: #00192d;
        -webkit-text-stroke: 1.2vh #00192d;
        text-align: center;
        transition: transform 0.3s;
        pointer-events: none;
        opacity: 1;
        transform: scale(1);


        &.fade-out {
            animation: shrink-fade-out 0.3s forwards;
        }

        &.fade-in {
            animation: grow-fade-in 0.3s forwards;
        }

        .bold-text {
            position: absolute;
            color: white;
            -webkit-text-stroke: initial;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 100%;
            color: white;
            text-align: center;
        }
    }

    @keyframes shrink-fade-out {
        0% {
            opacity: 1;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(0.5);
        }
    }

    @keyframes grow-fade-in {
        0% {
            opacity: 0;
            transform: scale(0.5);
        }

        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes slideDownFadeOut {
        0% {
            opacity: 1;
            transform: translateY(0);
        }

        50% {
            opacity: 0;
            transform: translateY(50%);
        }

        100% {
            opacity: 0;
            transform: translateY(100%);
        }
    }

    .animation-wrapper.slideDownFadeOut:not(:has(.lyric-linguist-video-wrapper)) {
        animation: slideDownFadeOut 1s ease forwards;
    }

    @keyframes slideUpFadeIn {
        0% {
            opacity: 0;
            transform: translateY(100%);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .animation-wrapper.hiddenAnim:not(:has(.lyric-linguist-video-wrapper)) {
        animation: hiddenAnim 1s ease forwards;
    }

    @keyframes hiddenAnim {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }

    .animation-wrapper.slideUpFadeIn:not(:has(.lyric-linguist-video-wrapper)) {
        animation: slideUpFadeIn 1s ease forwards;
    }

    @keyframes popIn {
        0% {
            opacity: 0;
            transform: scale(0.5);
        }

        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    .popIn {
        opacity: 0;
        animation: popIn 0.3s cubic-bezier(.48,.2,.61,1.32) forwards;
    }

    .popInDelay1 {
        animation-delay: 0s;
    }

    .popInDelay2 {
        animation-delay: 0.15s;
    }

    .popInDelay3 {
        animation-delay: 0.3s;
    }



    .intermission-container {
        height: 95vh;
        position: relative;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .header-toaster-game {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .header-toaster-in-game {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .popup-text-game {
        padding-bottom: 3vh;
     
    }

    .footer-toaster-game {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }


    .score-popup {
        display: flex;
        justify-content: center;
        align-items: center;
        animation: popIn 0.5s ease forwards;
        background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%) !important;
        border-radius: 3vh;
        z-index: 10 !important;
        width: 50vw;

        &:before, &:after {
            content: '';
            position: absolute;
            background: linear-gradient(45deg, #70FF75, #FAFF70, #70FF75, #FAFF70, #70FF75, #FAFF70, #70FF75, #FAFF70);
            background-size: 400%;
            width: calc(100% + 1vh) !important;
            height: calc(30.2vh + 1vh);
            border-radius: 3.4vh;
            z-index: -2 !important;
            animation: steam 25s linear infinite;
        }

        &:after {
            filter: blur(50px);
            z-index: -2 !important;
        }

        .score-box {
            padding: 6vh 8vh;
            border-radius: 3vh;
            text-align: center;
            font-size: 2vh;
            color: #333;
            display: flex;
            flex-direction: row;
            justify-content: center;
            background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%) !important;
            align-items: center;
            gap: 12vw;
            height: 30vh;
            width: 100%;


            .score-box-team {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                div {
                    margin-bottom: 1.5vh;

                    h1 {
                        color: white;
                        font-size: 3vh;
                        font-family: 'D-DIN DIN' !important;
                        font-weight: 600;
                        text-shadow: 4px 3px 3px rgba(0, 0, 0, 0.25);
                        margin: 0;
                    }

                    h2 {
                        color: white;
                        font-size: 10vh;
                        font-family: 'D-DIN DIN' !important;
                        font-weight: 600;
                        text-shadow: 5px 4px 3px rgba(0, 0, 0, 0.25);
                        margin: 0;
                    }
                }

                .team-bar {
                    position: absolute;
                    bottom: 0;
                    width: 3.5vh;
                    border-radius: 0.5vh;
                    background-color: #2476db;
                    transition: height 1s ease-in-out; /* Smooth height transition */
                }



                .teamA-bar {
                    background-color: #2476db;
                }

                .teamB-bar {
                    background-color: #e74c3c;
                }
            }
        }
    }





    @keyframes spotlightFadeIn {
        0% {
            opacity: 0;
            border-width: 0 0 80vh 0;
        }

        100% {
            opacity: 0.2;
            border-width: 0 20vh 80vh 20vh;
        }
    }

    @keyframes spotlightFadeIn2 {
        0% {
            opacity: 0;
            border-width: 0 0 80vh 0;
        }

        100% {
            opacity: 0.2;
            border-width: 0 15vh 80vh 15vh;
        }
    }

    @keyframes sptolightFloorFadeIn {
        0% {
            opacity: 0;
            width: 0;
        }

        100% {
            opacity: 0.5;
            width: 43vh;
        }
    }

    @keyframes sptolightFloorFadeIn2 {
        0% {
            opacity: 0;
            width: 0;
        }

        100% {
            opacity: 0.5;
            width: 33vh;
        }
    }

    @keyframes brainImageBobbing {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-15px);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes brainShadowWidth {
        0% {
            width: 20vh;
        }

        50% {
            width: 22vh;
        }

        100% {
            width: 20vh;
        }
    }


    .host-brain-container {
        position: absolute;
        top: 42vh;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: -1;

        .host-brain-image {
            height: 15vh;
            object-fit: contain;
            z-index: 0;
            animation: brainImageBobbing 3s ease-in-out forwards;
            animation-delay: 0.5s;
        }

        .host-brain-spotlight {
            position: absolute;
            top: -100vh;
            width: 0vh;
            height: 130vh;
            border-style: solid;
            border-width: 0 200px 800px 200px;
            border-color: transparent transparent #FFF transparent;
            transform: rotate(0deg);
            opacity: 0;
            filter: blur(1rem);
            animation: spotlightFadeIn 1s ease-in-out forwards;
            animation-delay: 0.5s;
        }

        .host-brain-spotlight-floor {
            position: absolute;
            width: 40vh;
            height: 2.5vh;
            background: white;
            -moz-border-radius: 100%;
            -webkit-border-radius: 100%;
            border-radius: 100%;
            top: 29.5vh;
            opacity: 0;
            filter: blur(0.1rem);
            animation: sptolightFloorFadeIn 1s ease-in-out forwards;
            animation-delay: 0.5s;
        }

        .host-brain-shadow {
            position: absolute;
            width: 20vw;
            height: 1.2vh;
            background: black;
            -moz-border-radius: 100%;
            -webkit-border-radius: 100%;
            border-radius: 100%;
            top: 30vh;
            opacity: 1;
            filter: blur(0.8vh);
            animation: brainShadowWidth 3s ease-in-out infinite;
            animation-delay: 0.5s;
        }
    }
    /*
    @media (max-height: 848px) {
        .host-brain-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: -1;

            .host-brain-image {
                height: 15vh;
                object-fit: contain;
                z-index: 0;
                animation: brainImageBobbing 3s ease-in-out infinite;
                animation-delay: 0.5s;
            }

            .host-brain-spotlight {
                position: absolute;
                bottom: -100%;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 0 20vh 80vh 20vh;
                border-color: transparent transparent #FFF transparent;
                transform: rotate(0deg);
                opacity: 0;
                filter: blur(1rem);
                animation: spotlightFadeIn2 1s ease-in-out forwards;
                animation-delay: 0.5s;
            }

            .host-brain-spotlight-floor {
                position: absolute;
                width: 15vw;
                height: 25px;
                background: white;
                -moz-border-radius: 430px / 25px;
                -webkit-border-radius: 430px / 25px;
                border-radius: 430px / 25px;
                top: 175%;
                opacity: 0;
                filter: blur(0.1rem);
                animation: sptolightFloorFadeIn2 1s ease-in-out forwards;
                animation-delay: 0.5s;
            }

            .host-brain-shadow {
                position: absolute;
                width: 20vw;
                height: 12px;
                background: black;
                -moz-border-radius: 200px / 12px;
                -webkit-border-radius: 200px / 12px;
                border-radius: 200px / 12px;
                bottom: -82%;
                opacity: 1;
                filter: blur(0.4rem);
                animation: brainShadowWidth 3s ease-in-out infinite;
                animation-delay: 0.5s;
            }
        }
    }*/
}




.WITW-leaderboard-animation {
    position: absolute;
    display: flex;
    top: 40%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;

    .host-witw-leaderboard-container {
        width: 50vh;
        background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%);
        border: #B1FFD0 0.3vh solid;
        border-radius: 2vh;
        justify-content: center;
        flex-direction: column;
        padding: 3vh 0;
        gap: 1vh;
        min-height: 8vh;

        .host-witw-leaderboard-player {
            height: 8vh;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 5vh;
            align-items: center;

            .host-witw-leaderboard-player-icon {
                height: 6vh;
                width: 6vh;

                img {
                    height: 100%;
                    border-radius: 50vh;
                    object-fit: cover;
                    aspect-ratio: 1/1;
                }
            }

            h1 {
                font-size: 4vh;
                color: white;
                text-align: center;
            }

            h2 {
                font-size: 4vh;

                span {
                    color: white;
                    opacity: 0.5;
                    font-size: 2.5vh;
                    font-style: italic;
                }
            }
        }
    }
}


    .one-team-container {
        width: 50vw !important;
    }

.game-over-wrapper {
    width: fit-content;
    border-radius: 5vh;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vh;
    width: 67vw;
    height: 50vh;
    background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 90%);

    &:before, &:after {
        content: '';
        position: absolute;
        background: linear-gradient(45deg, #70FF75, #FAFF70, #70FF75, #FAFF70, #70FF75, #FAFF70, #70FF75, #FAFF70);
        background-size: 400%;
        max-width: calc(50vw + 1vh);
        width: 100%;
        height: calc(50.2vh + 1vh);
        border-radius: 5.4vh;
        z-index: -1;
        animation: steam 25s linear infinite;
    }


    @keyframes steam {
        0% {
            background-position: 0 0;
        }

        50% {
            background-position: 400% 0;
        }

        100% {
            background-position: 0 0;
        }
    }

    &:after {
        filter: blur(50px);
    }

    .game-over-container {
        height: fit-content;
        border-radius: 4vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0vh 4vh 6vh 4vh;

        h1 {
            color: white;
            font-size: 8vh;
            font-weight: 700;
            text-transform: uppercase;
            font-family: 'D-DIN DIN' !important;
            margin: 0;
            text-shadow: 5px 4px 1px rgba(0, 0, 0, 0.2);
        }



        .game-over-players-draw {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-around;
        }

        .game-over-players-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            gap: 5vh;

            h2 {
                color: white;
                font-weight: 700;
                text-align: center;
                margin: 0;
                width: 100%;
                inline-size: 100%;
                font-size: 4vh;
                white-space: nowrap;
                text-transform: uppercase;
                font-family: 'D-DIN DIN' !important;
                text-shadow: 4px 3px 0px rgba(0, 0, 0, 0.2);
            }

            h1 {
                color: white;
                font-size: 2.5vh;
                opacity: 0.85;
                margin: 0 0 2vh 0;
                text-shadow: 3px 2px 1px rgba(0, 0, 0, 0.4);
            }
        }

        .game-over-players-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            gap: 4vh;


            .player-icon-container {
                width: 6vw;
                aspect-ratio: 1;
                display: flex;
                background: #ff8d75;
                border-radius: 50vh;
                justify-content: center;
                align-items: center;
                position: relative;
                box-shadow: 0px 3px 6px #4d0d00;

                .player-icon {
                    width: 90%;
                    height: 90%;
                    background-color: #aaa;
                    border-radius: 50%;
                    display: flex;



                    .player-icon-name-container {
                        position: absolute;
                        bottom: -15%;
                        right: 0;
                        height: 25%;
                        width: 6vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 0.5vh;
                        background: #d67763;
                        border: 0.15vh solid #ff8d75;
                        box-shadow: 0px 3px 6px #4d0d00;
                        overflow: hidden;

                        h3 {
                            color: white;
                            font-size: 1.35vh;
                            text-align: center;
                            width: 5vw;
                            font-family: 'D-DIN DIN' !important;
                            font-weight: 700;
                            letter-spacing: 0.1vh;
                            text-overflow: ellipsis;
                            text-transform: uppercase;
                            white-space: nowrap;
                            margin: 0;
                        }
                    }

                    .player-icon-image {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                        border-radius: 100px;
                    }
                }
            }
        }
    }

    .start-new-game-container {
        position: absolute;
        width: 30vw;
        height: 10vh;
        border: 0.5vh solid #75ffad;
        background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%);
        border-radius: 2vh;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 2vh;

        .game-over-button {
            border-radius: 2vh;
            width: 98%;
            height: 89%;
            color: white;
            font-size: 4vh;
            outline: none;
            background-color: transparent;
            border: none;
            font-weight: 700;
        }

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
            transition: transform 0.3s;
        }
    }
}
