.loadingBG {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;

    &.noBg {
        background-color: unset;
    }

    .animContainer {
        height: 40%;
        margin: auto;
        margin-bottom: 0;
    }

    .loader {
        margin-left: auto;
        margin-right: auto;
    }

    .barContainer {
        position: absolute;
        width: 75%;
        height: 4vh;
        background-color: white;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }


    .barSection {
        width: 100%;
        height: 5%;
        display: flex;
        position: relative;
        margin-top: 3vh;
        margin-bottom: auto;
    }


    .text {
        font-size: 3.75vh;
        text-align: center;
        margin-top: 7vh;
        margin-left: auto;
        margin-right: auto;
        color: black;
        margin-bottom: auto;
    }
}
