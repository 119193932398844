// Colors
$white: #fff;
$off-white: #F5F5F5;
$black: #000;
$off-black: #020202;

// Element Colors
$player-list-bg: #FFA500 ;

// Game Store Header Colors
$header-color-the-chameleon: #029080;
$header-color-colour-brain: #10232B;
$header-color-herd-mentality: #E56DA6;
$header-color-scrawl: #EC0080;
$header-color-can-of-squirms: #212B3D;
$header-color-draw-along-with-dave: #000000;
$header-color-pub-quiz: #545D6E;


$blue: #65BECD;
$blue-accent: #007DA5;
$hot-salmon: #FF5F6B;
$grey: #A6B5BD;

// Fonts
$font-primary: 'Lalezar';
$font-secondary: 'Lalezar';

// Spacing
$spacing-xs: 10px;
$spacing-sm: 20px;
$spacing-md: 40px;
$spacing-lg: 80px;

$menu-height: 100px;

// Screen Sizes
$screen-xs: 320px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

// Animation Speed
$speed-fast: .3s;
$speed-medium: .6s;
$speed-slow: 1s;

// Rounding
$rounding-xs: 5px;
$rounding-sm: 10px;
$rounding-md: 30px;
$rounded: 1000px;

// Mixins
@mixin drop-shadow() {
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.5);
}

@mixin disable-select() {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin image-background($src: null) {
    @if $src {
        background-image: url($src);
    }

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin image-background-fixed($src: null) {
    @include image-background($src);
    background-attachment: fixed;
}

@mixin abs-center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin button {
    .button {
        @include disable-select;
        margin-bottom: $spacing-sm;
        display: inline-block;
        padding: calc($spacing-xs / 2) $spacing-sm;
        font-size: 30px;
        font-family: $font-secondary;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        outline: none;
        color: $white;
        background-color: #ff55a0;
        border: none;
        border-radius: $rounding-sm;
        box-shadow: 0 9px #cc437e;
        transition: transform $speed-fast, box-shadow $speed-fast;

        &:hover {
            box-shadow: 0 5px #cc437e;
            transform: translateY(4px);
        }

        &:active {
            box-shadow: 0 1px #007DA5;
            transform: translateY(8px);
        }

        &.alternate {
            background-color: $hot-salmon;
            box-shadow: 0 9px #B3434B;

            &:hover {
                box-shadow: 0 5px #B3434B;
                transform: translateY(4px);
            }

            &:active {
                box-shadow: 0 1px #B3434B;
                transform: translateY(8px);
            }
        }

        &.wide {
            min-width: 100%;
        }

        &.medium {
            font-size: 25px;
            padding: calc($spacing-xs / 2) $spacing-sm;
        }

        &.small {
            font-size: 20px;
            padding: calc($spacing-xs / 2) $spacing-xs;
        }

        &:disabled {
            opacity: 0.5;

            &:hover {
                box-shadow: 0 9px #007DA5;
                transform: none;
            }
        }
    }
}

@mixin spinner {
    /* spinner/processing state, errors */
    .spinner,
    .spinner:before,
    .spinner:after {
        border-radius: 50%;
    }

    .spinner {
        color: #ffffff;
        font-size: 22px;
        text-indent: -99999px;
        margin: 0px auto;
        position: relative;
        width: 20px;
        height: 20px;
        box-shadow: inset 0 0 0 2px;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }

    .spinner:before,
    .spinner:after {
        position: absolute;
        content: '';
    }

    .spinner:before {
        width: 10.4px;
        height: 20.4px;
        background: #5469d4;
        border-radius: 20.4px 0 0 20.4px;
        top: -0.2px;
        left: -0.2px;
        -webkit-transform-origin: 10.4px 10.2px;
        transform-origin: 10.4px 10.2px;
        -webkit-animation: loading 2s infinite ease 1.5s;
        animation: loading 2s infinite ease 1.5s;
    }

    .spinner:after {
        width: 10.4px;
        height: 10.2px;
        background: #5469d4;
        border-radius: 0 10.2px 10.2px 0;
        top: -0.1px;
        left: 10.2px;
        -webkit-transform-origin: 0px 10.2px;
        transform-origin: 0px 10.2px;
        -webkit-animation: loading 2s infinite ease;
        animation: loading 2s infinite ease;
    }
}

// Form Colors
$input-color: #f4f4f4;
