@import "../shared/variables.scss";



div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.logoSection {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: fit-content;
    width: fit-content;
    transform-origin: top left;
    gap: 20px;

    h1 {
        color: $white;
    }

    &.topLeft {
        transform: scale(0.4);
        top: 3vh;
        left: 3vh;
        right: unset;
        bottom: unset;
    }

    .logo {
        display: none;
        height: 15vh;
        width: auto;
        margin: auto;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.mainBackground {
    background-image: url('../images/UCRedesignTVFix.png') !important;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: -2;
    top: 0;
    transition: all 1s ease-in-out;
}

.mainBackgroundZoom {
    background-image: url('../images/UCRedesignTVFix.png') !important;
    position: absolute;
    transform: scale(1.6) translate(-1%, 2%);
    width: 100vw;
    height: 100vh;
    top: 10vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: -2;
    transition: transform 1s ease-in-out, top 1s ease-in-out;
}

.menuBackground {
    background-image: url('../images/LandingBackground.jpg') !important;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: -2;
    transform: rotate(180deg)
}

.backgroundShadow {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(rgba(0, 0, 0, 0) 15%, #000 100%);
    z-index: 2;
    animation: fadeIn 1s ease-in-out forwards;
    transition: all 1s ease-in;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.gameContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    /*background-image: url('../images/Host/BG.png');*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: 0;

    .loadingLogo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .loadingContainer {
        position: absolute;
        left: 0;
        right: 0;
        /*top: 0;*/
        /* bottom: 0; */
        margin: auto;
        max-width: 700px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 1;
    }

    .instruction {
        position: absolute;
        right: 2%;
        top: 20%;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 2vw;
        padding: 2vw;
        color: white;
        width: 22vw;
        font-size: 1.2vw;
        text-align: center;
        transform: translateX(700px);
        transition: 0.3s transform;
        h1 {
            font-size: 3vw;
            margin: 0;
        }
        &.show {
            transform: translateX(0);
        }
    }


    .textAnimator {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-weight: bold;
        color: #333;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .text {
            position: absolute;
            opacity: 0;
            margin: auto;
            font-size: 2vh;
            left: 0;
            right: 0;
            top: 0;
            bottom: 5%;
            width: fit-content;
            color: white;
            height: fit-content;
            animation: fadeInOut 6s linear infinite;

            &.text1 {
                animation-delay: 0s;
            }

            &.text2 {
                animation-delay: 2s;
            }

            &.text3 {
                animation-delay: 4s;
            }
        }

        @keyframes fadeInOut {
            0% {
                opacity: 0;
            }

            10% {
                opacity: 1;
            }

            30% {
                opacity: 1;
            }

            40% {
                opacity: 0;
            }

            100% {
                opacity: 0;
            }
        }
    }


    .roomCode {
        position: absolute;
        right: 3vh;
        top: 1vh;
        margin: auto;
        display: flex;
        z-index: 1000;
        flex-direction: row;

        .textBox {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            height: fit-content;

            .text {
                text-align: end;
                font-size: 3.5vh;
                font-family: 'D-DIN Condensed', sans-serif;
                text-shadow: 0.25vh 0.5vh black;
                font-weight: normal;
                color: white;

                &:nth-child(1) {
                    transform: translateY(0.75vh);
                }

                &:nth-child(2) {
                    transform: translateY(-0.75vh);
                }

                &.code {
                    font-family: 'D-DIN Condensed', sans-serif;
                    text-shadow: 0.25vh 0.5vh black;
                    font-size: 4vh;
                    font-weight: bold;
                    color: #2ac4c4;
                }
            }

            .iconsBox {
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                flex-direction: row;
                width: fit-content;
                height: fit-content;
                z-index: 1000;

                .muteToggle {
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                    margin: auto;
                    cursor: pointer;
                    padding-left: 1vh;

                    .muteIcon {
                        width: auto;
                        height: 5vh;
                    }
                }
            }
        }

        .qrCodeBox {
            background-color: white;
            width: fit-content;
            height: fit-content;
            margin: auto;
            padding: 1vh;
            display: flex;
            border-radius: 1vh;
            margin-left: 1vh;
            z-index: 1000;
            margin-top: 1vh;

            .qrCode {
                height: 14vh !important;
                width: 14vh !important;
                margin: auto;
            }
        }
    }

    .timerSection {
        position: absolute;
        width: 25vh;
        height: 12.5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 0.5vh #00B497;
        background-color: #000000;
        border-radius: 4vh;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 2vh;
        padding: 1vh;
        transition: 1s transform;
        transform: translateY(150%);
        z-index: 11;

        &.show {
            transform: translateY(0%);
        }

        .timerText {
            text-align: center;
            font-size: 9vh;
            font-family: "Resistenza";
            color: white;
            width: fit-content;
            height: fit-content;
            line-height: 1;
            position: absolute;
            left: 0;
            right: 40%;
            bottom: 0;
            margin: auto;
            top: -5%;
        }

        .clock {
            height: 17vh;
            position: absolute;
            left: 0;
            right: 0;
            transform: translate(18%, -8%);
            margin: auto;
        }
    }

    @keyframes fadeBlackScreen {
        0% {
            opacity: 0;
        }

        25% {
            opacity: 1; /* Fade in */
        }

        75% {
            opacity: 1; /* Hold */
        }

        100% {
            opacity: 0; /* Fade out */
        }
    }

    .blackScreenOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: black;
        opacity: 1;
        pointer-events: none;
        z-index: 20; /* Ensure it's on top */
        animation: fadeBlackScreen 2s cubic-bezier(.87,.02,.73,.81) forwards; /* Fade in and out in 2 seconds */
    }

    .playerList {
        position: absolute;
        left: 49.3%;
        top: 48.5vh;
        transform: translate(-50%, -50%);
        width: 48vw;
        min-width: 950px;
        height: 50%;
        z-index: -2;
        padding: 3vh;
        gap: 2vh; // universal gap
        // The default layout if no special class matches:
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        // The popIn animation (optional, from your code):
        @keyframes popIn {
            from {
                transform: scale(0);
            }

            to {
                transform: scale(1);
            }
        }
        // Each .player gets the same styling by default

        @keyframes blinkIn {
            0% {
                opacity: 0
            }
         
            30% {
                opacity: 0.3
            }

            35% {
                opacity: 0
            }

            70% {
                opacity: 0.6
            }

         
            100% {
                opacity: 0.9
            }
        }

        .playerContainerBlink {
            animation: blinkIn 0.5s forwards;
            opacity: 0;

            &:nth-child(1) {
                animation-delay: 1.2s;
            }
            &:nth-child(2) {
                animation-delay: 1.3s;
            }
            &:nth-child(3) {
                animation-delay: 1.1s;
            }
            &:nth-child(4) {
                animation-delay: 1.2s;
            }
            &:nth-child(5) {
                animation-delay: 1.4s;
            }
            &:nth-child(6) {
                animation-delay: 1.2s;
            }
            &:nth-child(7) {
                animation-delay: 1.1s;
            }
            &:nth-child(8) {
                animation-delay: 1.3s;
            }
          
        }

        


        .player {
            position: relative;
            width: 12vw;
            height: 12vw;
            border-radius: 50%;
            background-color: rgba(56, 70, 143, 0.5);
            border: 3px white solid;
            margin-bottom: 1vh;

            .reconectionTimmer {
                position: absolute;
                width: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 7vh;
                color: #DC143C;
                text-shadow: 0vh 0 0.5vh black, 0vh 0 0.7vh black;
                text-align: center;
                font-weight: 700;
            }

            .image {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }

            .name {
                position: absolute;
                width: 100%;
                height: 20%;
                background-color: white;
                bottom: -5%;
                border-radius: 10px;
                display: flex;
                margin: auto;
                justify-content: center;
                align-items: center;

                h3 {
                    font-size: 1.4vw;
                    font-weight: 700;
                    color: black;
                    margin: 0;
                    text-align: center;
                }
            }
        }
    }

    .playerList.playerCount1 {
        display: flex;
        justify-content: center;
        align-items: center;

        .player {
            width: 22vw;
            height: 22vw;
        }
    }

    .playerList.playerCount2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: center;

        .player {
            width: 18vw;
            height: 18vw;
        }
    }

    .playerList.playerCount3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-items: center;

        .player {
            width: 12vw;
            height: 12vw;
        }
    }

    .playerList.playerCount4 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        justify-items: center;

        .player {
            width: 9vw;
            height: 9vw;
        }
    }

    .playerList.playerCount5 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        align-items: center;
        justify-items: center;

        .player {
            width: 10vw;
            height: 10vw;
        }

        .player:nth-child(1) {
            grid-column: 1 / 2;
            grid-row: 1;
        }

        .player:nth-child(2) {
            grid-column: 2 / 3;
            grid-row: 1;
        }

        .player:nth-child(3) {
            grid-column: 3 / 4;
            grid-row: 1;
        }

        .player:nth-child(4) {
            grid-column: 1 / 3;
            grid-row: 2;
        }

        .player:nth-child(5) {
            grid-column: 3 / 4;
            grid-row: 2;
            right: 50%;
        }
    }

    .playerList.playerCount6 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        align-items: center;
        justify-items: center;

        .player {
            width: 10vw;
            height: 10vw;
        }
    }

    .playerList.playerCount7 {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 1fr 1fr;
        align-items: center;
        justify-items: center;

        .player {
            width: 10vw;
            height: 10vw;
        }

        .player:nth-child(1) {
            grid-column: 1 / 2;
            grid-row: 1;
        }

        .player:nth-child(2) {
            grid-column: 2 / 3;
            grid-row: 1;
        }

        .player:nth-child(3) {
            grid-column: 3 / 4;
            grid-row: 1;
        }

        .player:nth-child(4) {
            grid-column: 4 / 5;
            grid-row: 1;
        }

        .player:nth-child(5) {
            grid-column: 1 / 2;
            grid-row: 2;
        }

        .player:nth-child(6) {
            grid-column: 2 / 3;
            grid-row: 2;
        }

        .player:nth-child(7) {
            grid-column: 3 / 4;
            grid-row: 2;
        }
    }

    .playerList.playerCount8 {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 1fr 1fr;
        align-items: center;
        justify-items: center;

        .player {
            width: 10vw !important;
            height: 10vw !important;
        }

        .player:nth-child(1) {
            grid-column: 1 / 2;
            grid-row: 1;
        }

        .player:nth-child(2) {
            grid-column: 2 / 3;
            grid-row: 1;
        }

        .player:nth-child(3) {
            grid-column: 3 / 4;
            grid-row: 1;
        }

        .player:nth-child(4) {
            grid-column: 4 / 5;
            grid-row: 1;
        }

        .player:nth-child(5) {
            grid-column: 1 / 2;
            grid-row: 2;
        }

        .player:nth-child(6) {
            grid-column: 2 / 3;
            grid-row: 2;
        }

        .player:nth-child(7) {
            grid-column: 3 / 4;
            grid-row: 2;
        }

        .player:nth-child(8) {
            grid-column: 4 / 4;
            grid-row: 2;
        }
    }

    .zoom {
        transform: scale(0.55) translate(-90%, -110%);
    }


    .mainButtonWrapper {
        width: 30vw;
        height: 10vh;
        background-color: #0d62dd;
        border-radius: 2vh;
        box-sizing: border-box;
        font-size: 3.5vh;
        display: flex;
        padding: 1vh; /* Initial padding */
        transition: padding 0.5s ease; /* Smooth transition back to normal padding */
        background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%);
        border: 0.5vh solid #75ffad;

        .mainButton {
            width: 100%;
            line-height: 1;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-color: unset;
            border: none;
            text-transform: uppercase;
            border-radius: 1.5vh;
            font-weight: 700;
            color: white;
            background: transparent;
            transition: all 0.3s;

            &:disabled {
                cursor: not-allowed;
            }
        }

        &:hover:enabled {
            padding: 1.5vh;
            transition: padding 0.3s ease;
        }

        &.disabled {
            background-color: #666;
            opacity: 0.7;
        }
    }

    @keyframes UCLogo {
        from {
            transform: scale(1.1);
            top: -100%;
        }

        to {
            transform: scale(1);
            top: 5%;
        }
    }
    /* Second Logo Animation (moves to top-left after clicking button) */
    @keyframes logoToTopLeft {
        from {
            transform: scale(1);
            bottom: 25%;
            top: inherit;
            left: inherit;
            right: inherit;
        }

        to {
            transform: scale(0.21);
            bottom: 80%;
            left: -90%;
        }
    }


    .UCLogo {
        animation: UCLogo 1s cubic-bezier(.44,.08,0,.99) forwards;
        animation-delay: 1s;
        position: absolute;
        left: 0;
        right: 0;
        top: -100%;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        z-index: 22;
        height: 25vh;
        transform: scale(1.1);
        width: auto;
    }

    .UCLogoMoveToTopLeft {
        animation: logoToTopLeft 1s ease-in-out forwards;
    }


    @keyframes startButtons {
        from {
            transform: translateY(150%); /* Start centered */
            height: 45%;
            opacity: 0;
        }

        to {
            opacity: 1;
            height: 20%;
            gap: 1vh;
            transform: translateY(calc(35vh - 5%)); /* Move downward, stopping at bottom 5% */
        }
    }

    .startButtonSection {
        animation: startButtons 1s cubic-bezier(.44,.08,0,.99) forwards;
        animation-delay: 1s;
        position: absolute;
        opacity: 0;
        left: 0;
        right: 0;
        top: 50%; /* Center vertically */
        transform: translateY(-50%); /* Perfect vertical centering */
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 11;
        justify-content: space-between;
        height: 25%;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            /*margin-top: -4vh;*/

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: black;
                margin: 0;
                font: inherit;
                width: 7.5vh;
                height: 7.5vh;
                border-radius: 2vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;

                &:hover {
                    background-color: darkgrey !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 5.5vh;
                height: 5.5vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: grey;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 2vh;
                font-size: 5.5vh;
                border-radius: 2vh;
                /*font-family: 'Resistenza';*/
                color: black;
            }
        }
    }

    .playerColumn {
        position: absolute;
        width: 20%;
        height: 75%;
        margin: auto;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all 2s;


        &.left {
            align-items: start;
            left: -30%;

            &.show {
                left: 0;
            }
        }

        &.right {
            right: -30%;
            align-items: end;

            &.show {
                right: 0;
            }
        }
    }

    .playAgainButtonSection {
        width: 55%;
        height: 20%;
        margin: auto;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 9;

        .button {
            position: relative;
            font-size: 4.5vh;
            /*font-family: "Resistenza";*/
            padding: 1vh;
            margin: 1vh;
            line-height: 1;
            /*            background-image: url('../images/Host/ButtonImg.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-color: unset;*/
            background-color: transparent;
            color: black;
            border: none;
            text-transform: uppercase;
            z-index: 1;

            .buttonImg {
                position: absolute;
                z-index: -1;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
