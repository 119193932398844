.selecting-team-container {
    padding: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;

    h1 {
        margin-top: 5vh;
        font-size: 4vh;
        font-weight: 700;
        margin-bottom: 5vh;
    }

    .selecting-team-player-icon {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        background-color: rgba(56, 70, 143, 0.5);
        margin-bottom: 1vh;
    }

    .selecting-team-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2vh;
        width: 100%;

        .team-container {
            position: relative;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;

            .team-selected {
                background-color: #0d62dd !important;
            }

            button {
                width: 100%;
                height: 15vh;
                font-size: 5vh;
                font-weight: 700;
                text-transform: uppercase;
                border: 0.5vh solid #75ffad;
                background: linear-gradient(#9cba77 0%, #7f9d7b 49.79%, #8caf77 100%);
                border-radius: 20px;
                color: white;
            }
        }
    }
}
