@import '../../shared/variables.scss';

@include button;

@include spinner;
@import url('https://fonts.cdnfonts.com/css/d-din');

body {
    font-family: 'D-DIN Condensed', sans-serif !important;
    touch-action: manipulation;

/*    iframe { // cant use this as stripe payments are an iframe lol
        pointer-events: none;
    }*/
}

* {
    font-family: 'D-DIN Condensed', sans-serif !important;
}
// This style sheet has become quite a mess

ul {
    padding-left: 0;
    li {
        list-style: none;
    }
}

.backgroundWrap {
    padding: 80px 0;
    z-index: 1000000;
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

label {
    color: $off-black;
    text-align: center;
    width: 100%;
}

input {
    width: 100%;
}




.mb-3 {
    text-align: start
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    gap: 20px;

    .divider {
        width: 5px;
        height: 250px;
        background-color: $grey;
        border-radius: 2.5px;

        @media (max-width: $screen-md) {
            display: none;
        }
    }


    .form-label {
        color: $off-black;
        text-align: start;
    }

    .QRContainer {
        display: flex;
        background-color: #3b414d;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 10px 0 0 #282c34;
        height: min-content;
        width: 190px;
        margin-bottom: 10px;

        @media (max-width: $screen-md) {
            display: none;
        }

        .QR {
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 0.3vh;
        align-items: start;
        justify-content: center;
        width: 100%;
    }

    .formInputs {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;
    }
}

.formWrap {
    position: relative;
    @include drop-shadow;
    padding: $spacing-md;
    max-width: 600px;
    margin: auto;
    border-radius: $rounding-sm;
    background-color: $off-white;

    @media (min-width: $screen-xs) {
        min-width: 300px;
    }

    @media (min-width: $screen-sm) {
        min-width: 400px;
    }

    @media (min-width: $screen-md) {
        min-width: 500px;
    }

    .formTitle {
        font-family: $font-secondary;
        color: $off-black;
        margin-bottom: $spacing-sm;
        font-weight: 500;
    }

    .form {
        width: 100%;
    }

    label, p {
        font-family: $font-primary;
        margin-bottom: $spacing-xs;
    }

    label {
        font-size: 18px;
        font-weight: 500;
    }

    input {
        background-color: $input-color;
    }

    .buttonsWrapper {
        display: flex;
        margin: 0 auto !important;
        justify-content: center;
        gap: 20px;
    }

    .passwordForget {
        color: #ff55a0;
        font-size: 17px;

        &:hover{
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .wrongMethod {
        color: #3b414d;
        font-size: 17px;
        margin-bottom: 0 !important;
    }

    .passwordRequirements {
        margin: $spacing-sm 0;
        padding: $spacing-sm 0;
        border: 2px solid $input-color;
        border-radius: $rounding-sm;

        li {
            list-style: none;
            font-size: 14px;
        }
    }
}

.checkoutForm {

    .checkoutTable {
        min-width: 100%;
        margin: 0;

        tr {
            text-align: left;
            border-bottom: 2px solid $grey;
            height: 50px;

            &:last-of-type {
                border-bottom: 0;
            }

            th, td, strong {
                font-family: $font-secondary;
                font-weight: 500;
            }

            th {
                font-size: 22px;
                color: $blue;

                @media (min-width: $screen-md) {
                    font-size: 28px;
                }
            }

            td {

                @media (min-width: $screen-md) {
                    font-size: 20px;
                }

                .productLogo {
                    max-width: 100px;
                    height: auto;
                    margin: $spacing-xs 0;

                    @media (min-width: $screen-md) {
                        max-width: 150px;
                    }
                }

                .amountPicker {
                    display: flex;
                    align-items: center;

                    .quantity {
                        border: 2px solid $grey;
                        min-width: 70px;
                        font-size: 18px;
                        position: relative;
                        text-align: center;
                        border-radius: $rounded;

                        &.checkoutStep {
                            border: 0px;
                        }

                        .minus, .add {
                            position: absolute;
                            top: -2px;
                            height: calc(100% + 4px);
                            background: $blue;
                            color: $white;
                            border: none;
                            padding: 0 7px;
                        }

                        .minus {
                            left: -2px;
                            border-radius: $rounding-sm 0 0 $rounding-sm;
                        }

                        .add {
                            right: -2px;
                            border-radius: 0 $rounding-sm $rounding-sm 0;
                        }
                    }
                }

                &.deleteColumn {
                    width: 22px;

                    button {
                        background: none;
                        border: none;
                        padding: 0;
                        font-size: 30px;
                    }
                }
            }

            &.total {
                td {
                    padding: $spacing-xs 0;

                    &:first-of-type {
                        color: $hot-salmon;
                        font-size: 26px;
                    }
                }
            }
        }
    }

    .couponSection {
        min-width: 100%;
        margin: 0;

        margin-top: $spacing-md;
        margin-bottom: $spacing-md;
        border: 2px solid $grey;
        border-radius: $rounding-sm;
        padding: $spacing-xs;
        .title {
            font-family: $font-secondary;
            color: $hot-salmon;
            font-size: 26px;
        }

        input {
             margin-bottom: $spacing-sm;
        }
    }
}

.productTable {
    min-width: 100%;
    margin-bottom: $spacing-sm;

    th, td, strong {
        font-family: $font-secondary;
        font-weight: 500;
    }

    th {
        font-size: 22px;
        color: $blue;

        @media (min-width: $screen-md) {
            font-size: 28px;
        }

        border-bottom: 2px solid $grey;
    }

    .product {
        position: relative;

        p {
            margin-bottom: 0;
        }

        .disabledProduct {
            input[type=checkbox] {
                display: none;
            }
        }

        border-bottom: 2px solid $grey;

        &:last-of-type {
            border-bottom: none;
        }

        button {
            background: none;
            border: none;
            padding: 0;
        }

        td {
            padding: $spacing-xs 0;
        }

        .productName {
            width: 100px;
            font-family: $font-secondary;
            font-size: 18px;
            margin-bottom: 0;
        }

        .productCode {
            max-width: 175px;
            font-size: 14px;
            font-family: $font-primary;
            word-wrap: break-word;
            padding: $spacing-xs;

            @media (min-width: $screen-md) {
                max-width: 250px;
            }
        }
    }
}

.buttonWrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 20px;
}

.activateModal {
    text-align: center;

    h2 {
        color: $black
    }

    color: $black;
    padding: $spacing-sm;

    .button {
        &:nth-of-type(1) {
            background-color: $hot-salmon;
            box-shadow: 0 9px #B3434B;
            
            &:hover {
                box-shadow: 0 5px #B3434B;
                transform: translateY(4px);
            }
        
            &:active {
                box-shadow: 0 1px #B3434B;
                transform: translateY(8px);
            }
        }
    }
}



