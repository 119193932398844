[contenteditable] {
    outline: 0px solid transparent;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.clientContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 700px !important;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-image: url('../images/UCMobileBackgroundFinal.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    color: white;


    .pauseContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        z-index: 10000;

        .pauseText {
            margin: auto;
            width: fit-content;
            height: fit-content;
            font-size: 8vh;
            color: white;
        }
    }

    .skipContainer {
        background-color: black;
        border-radius: 1vh;
        width: fit-content;
        height: fit-content;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;

        .skipButton {
            font-size: 4vh;
            color: white;
            text-align: center;
            padding: 1vh;
            margin: auto;
            position: relative;
        }
    }
}


