@import '../../shared/variables.scss';
@include button;

.QRContainer {
    display: block;
    justify-content: center;
    align-items: center;
    height: min-content;

    .QR {
    }

    a {
        text-decoration: none;
    }

    .scanMe {
        margin: 0;
        color: white;
        font-weight: bold;
        font-family: "D-DIN Condensed", sans-serif;
        font-size: 24px;
    }

    .center {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .expires {
        margin: 0;
        color: white;
        opacity: 1;
        font-family: "D-DIN Condensed", sans-serif;
        font-size: 15px;
    }
}
