@import url('https://fonts.cdnfonts.com/css/d-din');

body {
    font-family: 'D-DIN Condensed', sans-serif !important;
    touch-action: manipulation;

        iframe { // cant use this as stripe payments are an iframe lol
        pointer-events: none;
    }
}

.close-button {
    position: absolute;
    right: 1.2vh;
    top: 1vh;
    background: none;
    border-radius: 1vh;
    transition: transform 0.3s ease;
    padding: 0;
    border: none;

    img {
        width: 20px;
    }

    &:hover {
        transform: scale(1.1);
    }
}

@media (max-width: 1400px) {
    .landing-page-QR-container {
        //min-height: 80px !important;
        width: 10vw !important;
        padding: 1vh !important;
        border-radius: 1.5vh !important;
        //min-height: 240px;
        height: fit-content;

        div {
            .QR {
                display: none !important;
            }
        }

        img, h1, h2 {
            display: none;
        }

        button {
            margin: 0 !important;
        }
    }

    .help-container {
        .scroll-container {
            width: 90vw !important;

            h1 {
                font-size: 6vw !important;
            }

            h2 {
                font-size: 4vw !important;
            }

            h3 {
                font-size: 3vw !important;
            }

            h4 {
                font-size: 3vw !important;
            }
        }
    }

    .HEADER {
        width: 65% !important;
    }

    
}

.landing-page-bg {
    background-image: url('./images/parralex-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    .vignette-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        pointer-events: none;
        background: radial-gradient(rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
    }
}
.landing-page-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 1000000 !important;
    background-color: black !important;
    width: 100vw !important;
    height: 100vh !important;
    overflow-x: hidden !important;
    background-image: url('./images/parralex-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    display: flex;
    flex-direction: column;
    font-family: 'D-DIN Condensed', sans-serif;

    .nav-bar-container {
        width: 100vw;
        height: 10vh !important;
        min-height: 10vh;
        position: relative;
        background-color: #111419;
        z-index: 1000000;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 3vh;

        .logo {
            width: 7vh;
            height: 7vh;
        }

        .buttons-holder {
            display: flex;
            flex-direction: row;
            gap: 3vw;
            align-items: center;

            button {
                height: 5vh;
                border: transparent;
                outline: none;
                border-radius: 1vh;
                background-color: transparent;
                color: white;
                font-size: 2.5vh;
                transition: all 0.2s ease;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }


    .vignette-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        pointer-events: none;
        background: radial-gradient(rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
    }


    .stars-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        //vignette effect
        /*
        @keyframes anticlockwise {
            0% {
                transform: rotate(0deg) scale(3);
            }

            100% {
                transform: rotate(-360deg) scale(3);
            }
        }


        @keyframes clockwise {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(359deg);
            }
        }

        .big {
            position: fixed;
            opacity: 0.2;
            top: 0;
            animation: anticlockwise 150s linear infinite;
            transform: scale(2);
            width: 100%;
            height: 100%;
        }

        .small {
            position: absolute;
            animation: clockwise 150s linear infinite;
            top: 0;
        }*/
    }



    @media (max-width: 754px) {
        .hide-mobile {
            display: none !important;
        }

        .landing-page-mobile-header {
            opacity: 0 !important;
        }

        .logo {
            z-index: 100;
            width: 20vh;
        }

        .host-button {
            display: none !important;
        }

        .join-button {
            display: flex !important;
        }

        .HEADER {
            font-size: 4.2vh !important;
            width: 80% !important;
        }

        .HEADER-2 {
            font-size: 2.8vh !important;
            width: 95% !important;
            text-align: center;
        }

        .SHOW-MOBILE {
            opacity: 1 !important;
            display: block !important;
        }

        .HIDE-MOBILE {
            opacity: 0 !important;
            display: none !important;
        }

        .LL {
            font-size: 5.5vh !important;
        }

        .landing-page-splash-content {
            gap: 7vh !important;
            justify-content: space-around !important;
            height: 50vh !important;

            .container {
                display: flex;
                flex-direction: column;
                gap: 6vh !important;
            }
        }

        .landing-page-splash {
            height: 100vh !important;
            justify-content: start !important;
            position: relative;
            padding: 2vh !important;
            gap: 1.5vh !important;

            .splash-background {
                position: absolute;
                top: 0;
                height: 100vh;
            }
        }

        .landing-purchase-button {
            width: 30vw !important;
        }

        .landing-page-splash-icons {
            width: 90vw !important;
            min-width: 200px !important;

            .icon-container {
                min-width: 80px;
                min-height: 80px;
                max-height: 100px;
                max-width: 100px;
                width: 12vw !important;
                aspect-ratio: 1/1 !important
            }
        }

        .landing-page-QR-container {
            display: none !important;
        }

        .logout-mobile {
            display: flex !important;
        }

        .landing-page-rounds {
            top: 100vh !important;
        }

        .how-to-play-widget {
            padding: 3vh 1vh !important;
            gap: 3vh !important;

            h2 {
                font-size: 2.5vh !important;
            }

            .svg {
                width: 5vh !important;
            }
        }

        .how-to-play-widget-container {
            width: 80vw !important;

            .svg {
                width: 20vw !important;
            }
        }

        .how-to-play-widget h2 {
            font-size: 3.5vh !important;
        }
    }
    //dwadwadwa

    .landing-page-splash-container {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 50vh;

        .landing-page-splash {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 4vh;
            gap: 5vh;
            box-sizing: border-box;
            text-align: center;
            position: relative;

            .splash-background {
                position: absolute;
                top: 0;
                height: 100vh;
            }

            .landing-page-splash-contents {
                height: 100vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 3vh !important;

                img {
                    z-index: 100;
                    aspect-ratio: 1/1;
                    display: flex;
                    height: 25vh;
                    width: 25vh;
                }

                .logout-mobile {
                    position: absolute;
                    top: 1vh;
                    right: 1vh;
                    display: none;
                    width: fit-content;
                    height: fit-content;
                    border: transparent;
                    padding: 5px 10px;
                    outline: none;
                    border-radius: 1vh;
                    background-color: #085acf;
                    color: white;
                    font-size: 16px;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
                    transition: transform 0.2s ease;

                    &:hover {
                        transform: scale(1.05);
                    }
                }

                .landing-page-QR-container {
                    width: 12vw;
                    min-width: 150px;
                    height: fit-content;
                    background-color: #282c34;
                    position: absolute;
                    top: 3vh;
                    left: 3vh;
                    border-radius: 2.5vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 2vh;
                    gap: 0.5vh;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.65);

                    .QR-Code {
                        width: 80%;
                        aspect-ratio: 1/1;
                    }

                    h1 {
                        margin-top: 1vh;
                        color: white;
                        font-size: 2.5vh;
                        text-align: center;
                        margin-bottom: 0;
                    }

                    h2 {
                        color: white;
                        font-size: 2vh;
                        margin: 0;
                        text-align: center;
                        margin-bottom: 1vh;
                        opacity: 0.6;
                    }

                    button {
                        width: 100%;
                        height: 7vh;
                        border: transparent;
                        outline: none;
                        justify-self: end;
                        border-radius: 1vh;
                        background-color: #085acf;
                        color: white;
                        font-size: 2.5vh;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
                        transition: all 0.2s ease;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
            }

            .landing-page-splash-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5vh !important;
                width: 90vw !important;

                .container-2 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 4vh;
                    margin-top: 2vh;
                    height: fit-content;
                }

                .container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 2vh !important;
                    justify-content: center;
                    align-items: center;
                    height: fit-content;

                    HEADER {
                        width: 90vw !important;
                    }
                }

                .landing-page-splash-icons {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    min-width: 600px;
                    gap: 3vh;

                    .icon-container {
                        position: relative;
                        width: 16vh;
                        min-width: 100px;
                        background-color: white;
                        border-radius: 50%;
                        height: 16vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .dashed-line {
                            position: absolute;
                            width: 0;
                            height: 100%;
                            border: 0.2vh dashed white;
                            stroke-dasharray: 5;
                        }

                        .icon {
                            width: 90%;
                            height: 90%;
                        }
                    }
                }
            }

            .landing-purchase-button {
                position: relative;
                width: 30vw !important;
                height: 12vh;
                background-color: #ff55a0;
                border-radius: 1.5vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.65);
                border: none;
                display: flex;
                justify-content: center;
                transition: transform 0.3s ease;
                min-width: 200px;

                &.join-button {
                    display: none;
                }

                &:hover {
                    transform: scale(1.05);
                }

                h1 {
                    color: white;
                    font-size: 3.5vh;
                    text-align: center;
                    margin-bottom: 0;
                }

                h2 {
                    color: white;
                    font-size: 3vh;
                    margin: 0;
                    text-align: center;
                    opacity: 0.8;
                }
            }
        }
    }



    .landing-page-rounds {
        display: flex;
        flex-direction: column;
        height: fit-content;
        position: relative;
        top: 20vh !important;
        width: 100%;
        background: linear-gradient(rgba(12, 15, 22, 1) 0%, rgba(12, 15, 22, 0.8) 66.94%, #0c0f16 100%);
        border-radius: 3vh;
        text-align: center;
        align-items: center;
        gap: 10vh;
        padding: 3vh;

        .landing-page-rounds-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 3vh;

            .landing-page-content-games-round-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                gap: 5vh;
                row-gap: 7vh;
                justify-content: center;
                align-items: center;
                margin-top: 5vh;

                .round-widget-container {
                    width: 25vw;
                    min-width: 400px;
                    height: 33vh;
                    background-color: #00192E;
                    border-radius: 3vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0.8vh;

                    .round-widget {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        background-image: url('./images/RoundWidgetBackgroudn.png');
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        border-radius: 2vh;

                        .round-widget-header-container {
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 14vw;
                            min-width: 180px;
                            top: -3vh;
                            border-radius: 1vh;
                            height: 6vh;
                            background-color: #00192e;
                            padding: 0.5vh;

                            .round-widget-header {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: #2251a3 0.2vh solid;
                                border-radius: 0.8vh;
                                color: white;
                                font-size: 2.5vh;
                            }
                        }


                        .round-widget-content {
                            display: flex;
                            flex-direction: column;
                            gap: 0.5vh;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            padding: 1vh;


                            .connect-four-container {
                                height: 5vh;
                                width: 20vw;
                                min-width: 270px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: #00192e;
                                border-radius: 1vh;
                                padding: 0.3vh;

                                .connect-four {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: 0.2vh solid;
                                    color: white;
                                    font-size: 2vh;
                                    border-radius: 0.8vh;
                                }
                            }

                            .witw-map {
                                width: 14vh;
                            }

                            .lyric-linguist-container {
                                width: 90%;
                                background-color: #00192e;
                                border-radius: 2.5vh;
                                padding: 0.8vh;
                                height: fit-content;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 1vh;
                                }
                            }

                            .missing-vowels-container {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                gap: 1vh;
                                justify-content: center;
                                align-items: center;

                                .missing-vowel {
                                    display: flex;
                                    height: 100%;
                                    flex-direction: column;
                                    align-items: center;
                                    gap: 0.5vh;
                                    width: 3vh;
                                    justify-content: end;

                                    .missing-vowel-underscore {
                                        width: 100%;
                                        height: 1vh;
                                        background-color: white;
                                        border-radius: 0.5vh;
                                        border: #00192e 0.4vh solid;
                                    }
                                }
                            }

                            .face-smash-container {
                                width: 50%;
                                background-color: #00192e;
                                border-radius: 2.6vh;
                                padding: 0.8vh;
                                height: fit-content;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 1vh;
                                }
                            }
                        }



                        .round-widget-footer-container {
                            height: 6vh;
                            width: 12vw;
                            min-width: 180px;
                            position: absolute;
                            bottom: -3vh;
                            left: 50%;
                            transform: translateX(-50%);
                            border-radius: 1vh;
                            background-color: #00192e;
                            padding: 0.5vh;

                            .round-widget-footer {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 0.8vh;
                                color: white;
                                font-size: 2vh;

                                &.word-box {
                                    background-color: white;
                                    display: flex;
                                    flex-direction: row;
                                    gap: 0.7vh;
                                    align-items: end !important;
                                    padding-bottom: 1vh;
                                }

                                &.footer {
                                    background-color: #00192e;
                                    border: #2251a3 0.2vh solid;
                                }

                                .word-letter {
                                    width: 2.1vh;
                                    height: 0.3vh;
                                    background-color: #00192e;
                                }
                            }
                        }
                    }
                }
            }
        }

        .reviews-container {
            display: flex;
            flex-direction: row;
            gap: 4vh;
            flex: 0 0 100%;
            animation: scrolling 60s linear infinite;
            overflow: hidden;

            .group {
                display: flex;
                flex-direction: row;
                gap: 4vh;
                width: 100%;
                height: fit-content;

                .review-widget-wrapper {
                    width: 25vw;
                    height: 25vh;
                    padding: 1vh;
                    min-width: 300px;
                    min-height: 200px;
                    display: flex;
                    justify-content: center;
                    background-color: #FFF;
                    align-items: center;
                    border-radius: 1.5vh;

                    .review-widget-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        background-color: #FFF;
                        border-radius: 1vh;
                        padding: 2vh;
                        gap: 2vh;
                        width: F 100%;
                        height: 100%;
                        border: 0.2vh rgba(0,0,0,0.2) solid;

                        h1 {
                            color: black;
                            font-size: 2.5vh;
                        }

                        h2 {
                            color: black;
                            font-size: 2.3vh;
                            opacity: 0.8;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            gap: 1vh;

                            div {
                                display: flex;
                                flex-direction: row;
                                gap: 1vh;

                                img {
                                    width: 3vh;
                                }
                            }
                        }
                    }
                }
            }
        }



        .how-to-play-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            padding: 3vh;

            .how-to-play-container {
                margin-top: 4vh;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 5vh;
                width: 100%;
                height: 95%;

                .how-to-play-widget-container {
                    position: relative;
                    height: 40vh;
                    min-height: 400px;
                    width: 20vw;
                    min-width: 300px;
                    background-color: #1A1C20;
                    border-radius: 3vh;
                    padding: 1vh;

                    .how-to-play-widget {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        border: 0.3vh #222429 solid;
                        border-radius: 2vh;
                        padding: 5vh 2vh;
                        gap: 5vh;

                        .how-to-play-widget-index {
                            position: absolute;
                            top: -2vh;
                            left: -2vh;
                            width: 6vh;
                            height: 6vh;
                            background-color: #1A1C20;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 2vh;
                            color: white;
                            border: 0.3vh #222429 solid;
                        }

                        .svg {
                            width: 7vh;
                            fill: white;
                        }

                        h2 {
                            color: rgba(255,255,255, 0.8);
                            font-weight: 700;

                            span {
                                color: #ff55a0;
                            }
                        }
                    }
                }
            }
        }
    }
}
.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 5vh 5vw;
    width: 100vw;
    background-color: rgba(0,0,0,0.34);
    height: fit-content;
    gap: 5vh;

    h2 {
        width: 45%;
        color: white;
        opacity: 0.8;
        text-align: start;
    }

    img {
        height: 10vh;
    }

    div {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        gap: 3vw;
    }

    button {
        height: 6vh;
        text-align: start;
        border: transparent;
        outline: none;
        border-radius: 1vh;
        background-color: transparent;
        color: white;
        font-size: 2.5vh;
        transition: all 0.2s ease;

        &:hover {
            transform: scale(1.05);
        }
    }
}

.help-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 1000000 !important;
    background-color: black !important;
    width: 100vw !important;
    overflow-x: hidden !important;
    background-image: url('./images/parralex-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    display: flex;
    flex-direction: column;
    font-family: 'D-DIN Condensed', sans-serif;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
    min-height: 100%;

    .vignette-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        pointer-events: none;
        background: radial-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.9) 100%);
    }

    .scroll-container {
        border-radius: 3vh;
        width: 90%;
        background-color: rgba(12, 15, 22, 0.8);
        padding: 4vh;
        gap: 2vh;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 2vh;

        &.scroll-help {
            width: 65%;
            min-width: 370px;
            gap: 4vh;

            h1 {
                font-size: 5vh;
            }

            .help-illustration-container {
                width: 100%;
                height: 20vh;
                background-color: white;
                border-radius: 1vh;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
                display: flex;
                flex-direction: column;
                gap: 2vh;
                align-items: start;
                padding: 2vh;

                h1 {
                    color: black;
                    font-size: 3vh;
                    text-align: center;
                    margin-top: 1vh;
                    width: fit-content;
                }

                .container-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    width: 100%;

                    div {
                        display: flex;
                        flex-direction: column;
                        gap: 1vh;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 3vw;
                            min-width: 40px;
                        }

                        h2 {
                            color: black;
                            font-size: 2vh;
                            text-align: center;
                            margin: 0;
                            width: fit-content;
                        }
                    }
                }
            }
        }

        .table {

            th {
                color: white;
                font-size: 3vh;
                text-align: start;
                padding: 1vh 0;
                padding: 3vh;
            }

            td {
                color: white;
                font-size: 2.5vh;
                text-align: start;
                padding: 3vh;
            }
        }

        .divider {
            width: 100%;
            border: 1px rgba(255,255,255,0.4) solid;
            margin-top: 1vh;
            align-self: center;
        }

        .back-button {
            align-self: end;
            position: absolute;
            top: 2vh;
            right: 2vh;
            width: 10%;
            height: 5vh;
            border-radius: 1vh;
            border: none;
            outline: none;
            background-color: #ff55a0;
            color: white;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }
        }

        h1 {
            color: white;
            font-size: 7vh;
            width: 85%;
            margin-bottom: 0;
            text-transform: uppercase;
        }

        h2 {
            color: white;
            font-size: 4vh;
            margin-bottom: 0;
        }

        h3 {
            color: white;
            font-size: 3vh;
            margin-bottom: 0;
            opacity: 0.7
        }

        h4 {
            color: white;
            font-size: 3vh;
            margin-bottom: 0;
            opacity: 0.9;
            margin-top: 2vh;
        }

        h5 {
            color: white;
            padding-left: 3vw;
            margin: 2vh 0;
            font-size: 2.5vh;
            margin-bottom: 0;
            opacity: 0.7
        }

        div {
            display: flex;
            flex-direction: column;
        }
    }
}

.whats-next {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 1000000 !important;
    background-color: black !important;
    width: 100vw !important;
    overflow-x: hidden !important;
    background-image: url('./images/parralex-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    display: flex;
    flex-direction: column;
    font-family: 'D-DIN Condensed', sans-serif;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
    min-height: 100%;


    strong {
        font-size: min(4vw, 24px);
    }

    li {
        color: white;
        font-size: 20px;
        padding-bottom: min(1vw, 10px);
    }

    .vignette-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        pointer-events: none;
        background: radial-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.9) 100%);
    }

    .scroll-container {
        border-radius: 3vh;
        width: 90%;
        max-width: 800px;
        background-color: rgba(12, 15, 22, 0.8);
        padding: 4vh;
        gap: 2vh;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: auto;

        .back-button {
            align-self: end;
            position: absolute;
            top: 2vh;
            right: 2vh;
            width: fit-content;
            height: fit-content;
            border-radius: 1vh;
            border: none;
            outline: none;
            background-color: #ff55a0;
            color: white;
            transition: transform 0.3s ease;
            padding: 6px min(2vw, 20px);

            &:hover {
                transform: scale(1.05);
            }
        }

        h1 {
            color: white;
            font-size: min(8vw, 50px);
            width: 85%;
            margin-bottom: 0;
            text-transform: uppercase;
        }

        h2 {
            color: white;
            font-size: 4vh;
            margin-bottom: 0;
        }

        h3 {
            color: white;
            font-size: 3vh;
            margin-bottom: 0;
            opacity: 0.7
        }

        h4 {
            color: white;
            font-size: 3vh;
            margin-bottom: 0;
            opacity: 0.9;
            margin-top: 2vh;
        }

        h5 {
            color: white;
            padding-left: 3vw;
            margin: 2vh 0;
            font-size: 2.5vh;
            margin-bottom: 0;
            opacity: 0.7
        }

        div {
            display: flex;
            flex-direction: column;
        }
    }
}

.HEADER {
    font-size: 6.2vh;
}

.HEADER-2 {
    font-size: 3.7vh;
    width: 75%;
    text-align: center;
    
}

.SHOW-MOBILE {
    opacity:0;
    display: none;
}

.LL {
    font-size: 5.5vh;
}

.text {
    position: relative;
    font-weight: bold;
    font-family: 'D-DIN Condensed', sans-serif !important;
    line-height: 1;
    color: #021A2B;
    -webkit-text-stroke: 0.9vh black;
    transition: transform 0.3s;
    pointer-events: none;
    font-weight: 700;

    .face {
        position: absolute;
        color: white;
        -webkit-text-stroke: initial;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        font-weight: 700;
        color: white;
    }
}


@keyframes scrolling {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-33%);
    }
}

.landing-page-mobile-header {
    position: absolute;
    top: 0;
    left: 0;
    height: 5vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.2);
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    opacity: 0;
}