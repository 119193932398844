@import url('https://fonts.cdnfonts.com/css/d-din');
$spinner-base-color: #fff;
$spinner-highlight-color: #f00;
$background-color: #fff;

.selected {
    background-color: #0d62dd !important;
}

button {
    font-family: 'D-DIN Condensed', sans-serif;
}

.category-select-container {
    display: flex;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: inherit;
    align-items: inherit;
    gap: 2vh;
    padding: 3vh;
    box-sizing: border-box;
    font-weight: 800 !important;
    font-style: normal;
    font-family: "D-DIN Condensed",sans-serif !important;

    .category-select-container-headers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .category-select-user-icon {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            background-color: rgba(56, 70, 143, 0.5);
            margin-bottom: 1vh;
        }

        h1 {
            font-size: 5vh;
            font-weight: 700;
            color: white;
            margin: 0 0 1vh 0;
        }

        h2 {
            width: 90%;
            font-size: 2.5vh;
            font-weight: 500;
            color: white;
            margin: 0;
            opacity: 0.7;
        }
    }

    .category-select-user-age {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            font-size: 3vh;
            font-weight: 600;
            color: white;
            margin: 0 0 1vh 0;
        }

        input {
            width: 80%;
            background-color: white;
            outline: none;
            color: black;
            padding: 4vw;
            text-align: center;
            border: #0d62dd 5px solid;
            text-transform: uppercase;
            font-family: 'D-DIN Condensed';
            font-weight: 500;
            border-radius: 5px
        }
    }


    .category-select-subjects-container {
        display: flex;
        flex-direction: column;
        gap: 1vw;
        -webkit-justify-content: center;
        justify-content: center;
        text-align: center;

        h2 {
            font-size: 2.5vh;
            font-weight: 600;
            color: white;
            margin: 1vh 0 0 0;
        }

        .category-select-genius-subjects {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 0.5vw;

            button {
                background-color: white;
                border: transparent;
                color: black;
                padding: 1.5vh 0;
                text-transform: uppercase;
                font-family: 'D-DIN Condensed';
                font-weight: 500;
                border-radius: 5px
            }
        }


        .category-select-flex-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 1vw;

            button {
                background-color: white;
                color: black;
                border: transparent;
                padding: 1vh 0;
                text-transform: uppercase;
                font-family: 'D-DIN Condensed';
                font-weight: 500;
                border-radius: 5px
            }
        }
    }

    .next-button-wrapper {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
        background-color: #0d62dd;
        padding: 10px;
        border-radius: 10px;

        .next-button {
            background-color: #0d62dd;
            border: white 2px solid;
            color: white;
            padding: 1vw 0;
            text-transform: uppercase;
            font-family: 'D-DIN Condensed';
            font-weight: 600;
            width: 100%;
            border-radius: 5px;
            font-size: 2.5vh;
        }
    }
}

.awaiting-player-avatars {
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;

    h1 {
        width: 80%;
        text-align: center;
        font-size: 4vh
    }
}

.upload-avatar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3vh;
    padding: 1vh;
    padding: 3vw;

    button {
        background-color: white;
        color: black;
        border: transparent;
        padding: 1vh;
        text-transform: uppercase;
        font-family: 'D-DIN Condensed';
        font-weight: 500;
        border-radius: 5px;
        font-size: 2em;
    }

    h4 {
        font-size: 2vh;
        text-align: center;
        opacity: 0.8;
        font-weight: 500;
        color: white;
        margin: 0;
    }

    .avatar-preview-container {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .pre-made-avatar-preview {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 500px;
            aspect-ratio: 1/1;
        }
    }

    .avatar-grid {
        //position: absolute;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        //bottom: 10px;
        margin: 0 10px;
    }

    .avatar-cell {
        cursor: pointer;
        text-align: center;

        .pre-made-avatar {
            width: 100%;
            height: auto;
            border-radius: 500px;
            object-fit: cover;
            aspect-ratio: 1/1;
        }

        .create-own-avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 500px;
            background-color: white;
            aspect-ratio: 1/1;
            color: black;
            height: 30vh;
            width: 30vh;

            h3 {
                font-size: 4vh;
                font-weight: 400;
                text-align: center;
                margin: 0;
                font-family: 'D-DIN Condensed';
            }
        }
    }



    .alternative-upload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vh;

        button {
            background-color: white;
            color: black;
            border: transparent;
            padding: 1vh;
            text-transform: uppercase;
            font-family: 'D-DIN Condensed';
            font-weight: 500;
            border-radius: 5px;
            font-size: 2em;
        }

        input {
            position: absolute;
            bottom: 5vh;
        }
    }

    .camera-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vh;
        position: relative;

        .camera-stencil {
            position: absolute;
            width: 120%;
            top: -5%;
            pointer-events: none;
        }

        .camera-feed {
            border-radius: 500px;
            aspect-ratio: 1/1;
            object-fit: cover;
        }

        button {
            background-color: white;
            color: black;
            border: transparent;
            padding: 1vh;
            text-transform: uppercase;
            font-family: 'D-DIN Condensed';
            font-weight: 500;
            border-radius: 5px;
            font-size: 2em;
        }
    }

    .upload-retake-buttons-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 20px;

        button {
            width: 60vw;
            font-size: 4vh;
            padding: 0.5vh;
        }
    }

    .captured-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        h1, h2, h3 {
            color: white;
            font-size: 1.5em;
            font-family: 'D-DIN Condensed';
            font-weight: 500;
        }

        .captured-image {
            border-radius: 500px;
            max-width: 400px;
            aspect-ratio: 1/1;
            object-fit: cover;
        }

        .avatar-preview-container {
            position: relative;
            width: 200px;

            .player-av-waiting {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: black;
                border-radius: 500px;
                opacity: 0.5;
            }
        }

        .captured-image-buttons-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 2vh;
            margin-top: 10vh;

            button {
                background-color: white;
                color: black;
                border: transparent;
                padding: 0.5vh;
                text-transform: uppercase;
                font-family: 'D-DIN Condensed';
                font-weight: 500;
                border-radius: 5px;
                font-size: 4vh;
                width: 60vw;
            }

            .upload-image-section-buttons {
                text-align: center;
            }

            .upload-image-grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 1vh;

                button {
                    background-color: white;
                    color: black;
                    border: transparent;
                    padding: 0.5vh;
                    text-transform: uppercase;
                    font-family: 'D-DIN Condensed';
                    font-weight: 500;
                    border-radius: 5px;
                    font-size: 1.5em;
                    width: 60vw;
                }
            }
        }
    }
}

@keyframes loadingI {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes bounce {
    0%, 100% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}

.spinner-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    .spinner {
        width: 45px;
        height: 45px;
        margin: 0 auto;
        animation: loadingI 2s linear infinite;

        .bubble-1 {
            position: absolute;
            top: 0;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            background-color: #4b9cdb;
            animation: bounce 2s ease-in-out infinite;
        }

        .bubble-2 {
            position: absolute;
            top: 0;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            background-color: #4b9cdb;
            animation: bounce 2s ease-in-out infinite;
            animation-delay: -1s;
        }
    }
}

